import { Component } from '@angular/core';

@Component({
  selector: 'app-avisos',
  templateUrl: './avisos.component.html',
  styleUrls: ['./avisos.component.scss']
})


export class AvisosComponent {


ngOnInit(): void {

this.objeto()


}

objeto(){


}

}
