
import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { CommonModule } from '@angular/common';
import { FraccionIComponent } from '../articulo15/fraccion-i/fraccion-i.component';
import { BlogSingleComponent } from '../articulo8/blog-single.component';
import { Articulo15Component } from '../articulo15/articulo15.component';

import { InAComponent } from '../articulo8/Fraccion1/in-a/in-a.component';
import { InBComponent } from '../articulo8/Fraccion1/in-b/in-b.component';
import { InCComponent } from '../articulo8/Fraccion1/in-c/in-c.component';
import { InDComponent } from '../articulo8/Fraccion1/in-d/in-d.component';
import { InEComponent } from '../articulo8/Fraccion1/in-e/in-e.component';
import { InFComponent } from '../articulo8/Fraccion1/in-f/in-f.component';
import { InGComponent } from '../articulo8/Fraccion1/in-g/in-g.component';
import { InHComponent } from '../articulo8/Fraccion1/in-h/in-h.component';
import { InIComponent } from '../articulo8/Fraccion1/in-i/in-i.component';
import { InJComponent } from '../articulo8/Fraccion1/in-j/in-j.component';
import { InKComponent } from '../articulo8/Fraccion1/in-k/in-k.component';
import { InLComponent } from '../articulo8/Fraccion1/in-l/in-l.component';
import { InMComponent } from '../articulo8/Fraccion1/in-m/in-m.component';
import { InNComponent } from '../articulo8/Fraccion1/in-n/in-n.component';
import { InN1Component } from '../articulo8/Fraccion1/in-n1/in-n1.component';
import { In2AComponent } from '../articulo8/Fraccion2/in2-a/in2-a.component';
import { In2BComponent } from '../articulo8/Fraccion2/in2-b/in2-b.component';
import { In2CComponent } from '../articulo8/Fraccion2/in2-c/in2-c.component';
import { In2DComponent } from '../articulo8/Fraccion2/in2-d/in2-d.component';
import { In2EComponent } from '../articulo8/Fraccion2/in2-e/in2-e.component';
import { In3AComponent } from '../articulo8/Fraccion3/in3-a/in3-a.component';
import { In3BComponent } from '../articulo8/Fraccion3/in3-b/in3-b.component';
import { In3CComponent } from '../articulo8/Fraccion3/in3-c/in3-c.component';
import { In3DComponent } from '../articulo8/Fraccion3/in3-d/in3-d.component';
import { In3EComponent } from '../articulo8/Fraccion3/in3-e/in3-e.component';
import { In3FComponent } from '../articulo8/Fraccion3/in3-f/in3-f.component';
import { In3GComponent } from '../articulo8/Fraccion3/in3-g/in3-g.component';
import { In4AComponent } from '../articulo8/Fraccion4/in4-a/in4-a.component';
import { In4BComponent } from '../articulo8/Fraccion4/in4-b/in4-b.component';
import { In4CComponent } from '../articulo8/Fraccion4/in4-c/in4-c.component';
import { In4DComponent } from '../articulo8/Fraccion4/in4-d/in4-d.component';
import { In4EComponent } from '../articulo8/Fraccion4/in4-e/in4-e.component';
import { In4FComponent } from '../articulo8/Fraccion4/in4-f/in4-f.component';
import { In4GComponent } from '../articulo8/Fraccion4/in4-g/in4-g.component';
import { In4HComponent } from '../articulo8/Fraccion4/in4-h/in4-h.component';
import { In4IComponent } from '../articulo8/Fraccion4/in4-i/in4-i.component';
import { In5AComponent } from '../articulo8/Fraccion5/in5-a/in5-a.component';
import { In5BComponent } from '../articulo8/Fraccion5/in5-b/in5-b.component';
import { In5CComponent } from '../articulo8/Fraccion5/in5-c/in5-c.component';
import { In5DComponent } from '../articulo8/Fraccion5/in5-d/in5-d.component';
import { In5EComponent } from '../articulo8/Fraccion5/in5-e/in5-e.component';
import { In5FComponent } from '../articulo8/Fraccion5/in5-f/in5-f.component';
import { In5GComponent } from '../articulo8/Fraccion5/in5-g/in5-g.component';
import { In5HComponent } from '../articulo8/Fraccion5/in5-h/in5-h.component';
import { In5IComponent } from '../articulo8/Fraccion5/in5-i/in5-i.component';
import { In5JComponent } from '../articulo8/Fraccion5/in5-j/in5-j.component';
import { In5KComponent } from '../articulo8/Fraccion5/in5-k/in5-k.component';
import { In5LComponent } from '../articulo8/Fraccion5/in5-l/in5-l.component';
import { In5MComponent } from '../articulo8/Fraccion5/in5-m/in5-m.component';
import { In5NComponent } from '../articulo8/Fraccion5/in5-n/in5-n.component';
import { In5N1Component } from '../articulo8/Fraccion5/in5-n1/in5-n1.component';
import { In5OComponent } from '../articulo8/Fraccion5/in5-o/in5-o.component';
import { In5PComponent } from '../articulo8/Fraccion5/in5-p/in5-p.component';
import { In5QComponent } from '../articulo8/Fraccion5/in5-q/in5-q.component';
import { In5RComponent } from '../articulo8/Fraccion5/in5-r/in5-r.component';
import { In5SComponent } from '../articulo8/Fraccion5/in5-s/in5-s.component';
import { In5TComponent } from '../articulo8/Fraccion5/in5-t/in5-t.component';
import { In5UComponent } from '../articulo8/Fraccion5/in5-u/in5-u.component';
import { In5VComponent } from '../articulo8/Fraccion5/in5-v/in5-v.component';
import { In5WComponent } from '../articulo8/Fraccion5/in5-w/in5-w.component';
import { In5XComponent } from '../articulo8/Fraccion5/in5-x/in5-x.component';
import { In5YComponent } from '../articulo8/Fraccion5/in5-y/in5-y.component';
import { In5ZComponent } from '../articulo8/Fraccion5/in5-z/in5-z.component';
import { In6AComponent } from '../articulo8/Fraccion6/in6-a/in6-a.component';
import { In6BComponent } from '../articulo8/Fraccion6/in6-b/in6-b.component';
import { In6CComponent } from '../articulo8/Fraccion6/in6-c/in6-c.component';
import { In6DComponent } from '../articulo8/Fraccion6/in6-d/in6-d.component';
import { In6EComponent } from '../articulo8/Fraccion6/in6-e/in6-e.component';
import { In6FComponent } from '../articulo8/Fraccion6/in6-f/in6-f.component';
import { In6GComponent } from '../articulo8/Fraccion6/in6-g/in6-g.component';
import { In6HComponent } from '../articulo8/Fraccion6/in6-h/in6-h.component';
import { In6IComponent } from '../articulo8/Fraccion6/in6-i/in6-i.component';
import { In6JComponent } from '../articulo8/Fraccion6/in6-j/in6-j.component';
import { In6KComponent } from '../articulo8/Fraccion6/in6-k/in6-k.component';
import { In6LComponent } from '../articulo8/Fraccion6/in6-l/in6-l.component';
import { In6MComponent } from '../articulo8/Fraccion6/in6-m/in6-m.component';
import { In6NComponent } from '../articulo8/Fraccion6/in6-n/in6-n.component';
import { Fra7Component } from '../articulo8/Fraccion7/fra7/fra7.component';
import { Fra8Component } from '../articulo8/Fraccion8/fra8/fra8.component';
import { Fra9Component } from '../articulo8/Fraccion9/fra9/fra9.component';
import { Fra10Component } from '../articulo8/Fraccion10/fra10/fra10.component';
import { Fra11Component } from '../articulo8/Fraccion11/fra11/fra11.component';
import { Fra12Component } from '../articulo8/Fraccion12/fra12/fra12.component';
import { Fra13Component } from '../articulo8/Fraccion13/fra13/fra13.component';
import { Fra14Component } from '../articulo8/Fraccion14/fra14/fra14.component';
import { FraccionIIComponent } from '../articulo15/fraccion-ii/fraccion-ii.component';
import { FraccionIIIComponent } from '../articulo15/fraccion-iii/fraccion-iii.component';
import { FraccionIVComponent } from '../articulo15/fraccion-iv/fraccion-iv.component';
import { FraccionVComponent } from '../articulo15/fraccion-v/fraccion-v.component';
import { FraccionVIComponent } from '../articulo15/fraccion-vi/fraccion-vi.component';
import { FraccionVIIComponent } from '../articulo15/fraccion-vii/fraccion-vii.component';
import { FraccionVIIIComponent } from '../articulo15/fraccion-viii/fraccion-viii.component';
import { FraccionIXComponent } from '../articulo15/fraccion-ix/fraccion-ix.component';
import { FraccionXComponent } from '../articulo15/fraccion-x/fraccion-x.component';
import { FraccionXIComponent } from '../articulo15/fraccion-xi/fraccion-xi.component';
import { FraccionXIIComponent } from '../articulo15/fraccion-xii/fraccion-xii.component';
import { FraccionXIIIComponent } from '../articulo15/fraccion-xiii/fraccion-xiii.component';
import { FraccionXIVComponent } from '../articulo15/fraccion-xiv/fraccion-xiv.component';
import { FraccionXVComponent } from '../articulo15/fraccion-xv/fraccion-xv.component';
import { FraccionXVIComponent } from '../articulo15/fraccion-xvi/fraccion-xvi.component';
import { FraccionXVIIComponent } from '../articulo15/fraccion-xvii/fraccion-xvii.component';
import { FraccionXVIIIComponent } from '../articulo15/fraccion-xviii/fraccion-xviii.component';
import { FraccionXIXComponent } from '../articulo15/fraccion-xix/fraccion-xix.component';
import { FraccionXXIComponent } from '../articulo15/fraccion-xxi/fraccion-xxi.component';
import { FraccionXXIIComponent } from '../articulo15/fraccion-xxii/fraccion-xxii.component';
import { FraccionXXIIIComponent } from '../articulo15/fraccion-xxiii/fraccion-xxiii.component';
import { FraccionXXIVComponent } from '../articulo15/fraccion-xxiv/fraccion-xxiv.component';
import { FraccionXXVComponent } from '../articulo15/fraccion-xxv/fraccion-xxv.component';
import { FraccionXXVIComponent } from '../articulo15/fraccion-xxvi/fraccion-xxvi.component';
import { FraccionXXVIIComponent } from '../articulo15/fraccion-xxvii/fraccion-xxvii.component';
import { FraccionXXVIIIComponent } from '../articulo15/fraccion-xxviii/fraccion-xxviii.component';
import { FraccionXXComponent } from '../articulo15/fraccion-xx/fraccion-xx.component';
import { LoginComponent } from '../LOGIN/login/login.component';

import { DocumentoComponent } from '../documento/documento.component';

import { EnlacesComponent } from '../enlaces/enlaces.component';

import { DocumentoIMComponent } from '../documento/documento-im/documento-im.component';
import { AvisosComponent } from '../avisos/avisos.component';







const routes: Routes = [
  /*{
    path: '',
    component: DashboardComponent,
  },*/
        {
          path:'articulo-8/fraccion-5/in-l',
          component: In5LComponent
        },
        {
          path:'subir-documento',
          component: DocumentoIMComponent
        }
        ,
        {
          path:'avisos-de-privacidad',
          component: AvisosComponent
        }



];

@NgModule({
  imports: [CommonModule,RouterModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class DashboardRoutingModule {}
