import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidenav-admin',
  templateUrl: './sidenav-admin.component.html',
  styleUrls: ['./sidenav-admin.component.scss']
})
export class SidenavAdminComponent implements OnInit {
  token = false;
  constructor(private router: Router) { }

  ngOnInit(): void {
    if (localStorage.getItem("token")) {
      this.token = true;
    } else {
      this.token = false
    }

  }

  instituto() {
    localStorage.setItem('im', '1')
  }
  dif() {

    localStorage.setItem('dif', '1')



  }
  cerrar() {
    localStorage.clear()
    location.reload()
  }
  subirDocumento() {
    localStorage.setItem('articulo', '8');
    console.log(localStorage.getItem('articulo'));
    this.router.navigate(['admin/documento']);
  }
  subirDocumento15() {
    localStorage.setItem('articulo', '15');
    console.log(localStorage.getItem('articulo'));
    this.router.navigate(['admin/documento']);
  }
}
