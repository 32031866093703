<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <meta content="" name="description">
  <meta content="" name="keywords">

  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>

  <!--Main layout-->
  <main style="margin-top: 40px;">
    <div class="container pt-4"></div>
  </main>
  <!--Main layout-->
  <main id="main">



    <!-- ======= Breadcrumbs ======= -->
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">

        <div class="d-flex justify-content-between align-items-center">
          <ol>
            <li>Avisos de Privacidad</li>

          </ol>
        </div>

      </div>
    </section><!-- End Breadcrumbs -->

    <section id="blog" class="blog">

      <div class="fondo" data-aos="fade-up">

        <div id="scroll">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Avisos de Privacidad</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="width: 100%;">
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong> ADMINISTRACIÓN DE CEMENTERIOS</strong>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10790G1AVISO%20DE%20PRIVACIDAD%20CORTO%20ADMINISTRACI%C3%93N%20CEMENTERIOS.pdf?alt=media&token=b256bc59-8547-4f36-b2d8-98a94d05854f"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO ADMINISTRACIÓN CEMENTERIOS </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10790G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20ADMINISTRACI%C3%93N%20DE%20CEMENTERIOS.pdf?alt=media&token=d0ecec08-0923-4361-b789-f7d643b969cf"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL ADMINISTRACIÓN DE CEMENTERIOS
                  </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10790G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20ADMINISTRACI%C3%93N%20CEMENTERIOS.pdf?alt=media&token=e8d69161-d6a7-4ef6-a29d-1036123ff26c"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO ADMINISTRACIÓN CEMENTERIOS
                  </a>
                </td>

              </tr>

              <tr>


                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong> ADMINISTRACIÓN MERCADOS</strong>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10790G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20ADMINISTRACI%C3%93N%20DE%20MERCADOS.pdf?alt=media&token=6d831656-8423-40c3-824d-34f0efff0d98"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO ADMINISTRACIÓN DE MERCADOS
                  </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10790G1AVISO%20DE%20PRIVACIDAD%20CORTO%20ADMINISTRACI%C3%93N%20DE%20MERCADOS%20.pdf?alt=media&token=eef7daf6-01d3-4542-a8f7-badfdc482256"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO ADMINISTRACIÓN DE MERCADOS </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20ADMINISTRACI%C3%93N%20DE%20MERCADOS.pdf?alt=media&token=8bd2ebd1-3df3-41be-8792-b2bd9e4fcae6
                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL ADMINISTRACIÓN DE MERCADOS
                  </a>
                </td>
              </tr>


              <tr>


                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong> ALUMBRADO PÚBLICO</strong>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10790G1AVISO-DE-PRIVACIDAD-INTEGRAL-ALUMBRADO-PUBLICO.pdf?alt=media&token=e951f805-eb7b-4d40-aee6-d3883671b87d"
                    target="_blank" style="text-align: end;">AVISO-DE-PRIVACIDAD-INTEGRAL-ALUMBRADO-PUBLICO </a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10790G1AVISO%20DE%20PRIVACIDAD%20CORTO%20ALUMBRADO%20PUBLICO%2021-24.pdf?alt=media&token=cb2a459b-1d29-4a92-babf-b7ffda250e9c"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO ALUMBRADO PUBLICO 21-24</a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10790G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20ALUMBRADO%20PUBLICO%2021-24.pdf?alt=media&token=e3072606-84aa-48f0-beb3-2fd0091036a1"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO ALUMBRADO PUBLICO 21-24
                  </a>
                </td>

              </tr>


              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong> COMISARIA DE SEGURIDAD PÚBLICA</strong>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10790G1AVISO%20DE%20PRIVACIDAD%20CORTO%20COMISARIA%20DE%20SEGURIDAD%20P%C3%9ABLICA%20AUTL%C3%81N%20DE%20NAVARO%2C%20JALISCO.pdf?alt=media&token=93cf95f1-3bea-4702-ad39-18b5ba80e8f3"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO COMISARIA DE SEGURIDAD PÚBLICA
                    AUTLÁN DE NAVARO, JALISCO</a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10790G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20COMISARIA%20DE%20SEGURIDAD%20P%C3%9ABLICA%20AUTL%C3%81N%20DE%20NAVARO%2C%20JALISCO.pdf?alt=media&token=b2a3e11d-6e9b-40b1-bc91-86264d95727d"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO COMISARIA DE SEGURIDAD
                    PÚBLICA AUTLÁN DE NAVARO, JALISCO</a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20-%20COMISARIA%20DE%20SEGURIDAD%20PUBLICA.pdf?alt=media&token=1611539d-5dba-4f11-b7d0-c23b5cc37730
                  " target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL COMISARIA DE SEGURIDAD
                    PÚBLICA AUTLÁN DE NAVARO, JALISCO </a>
                </td>
              </tr>


              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong> CONSEJOS MUNICIPALES DE SALUD</strong>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10790G1AVISO%20DE%20PRIVACIDAD%20CORTO%20CONSEJOS%20MUNICIPALES%20DE%20SALUD.pdf?alt=media&token=ca56db95-7f9a-42cd-88bf-2840aea95a4d"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO CONSEJOS MUNICIPALES DE SALUD</a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20CONSEJOS%20MUNICIPALES%20DE%20SALUD.pdf?alt=media&token=9dc83693-ff36-4816-8246-311ca40a7bac"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL CONSEJOS MUNICIPALES DE
                    SALUD</a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10790G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20CONSEJOS%20MUNICIPALES%20DE%20SALUD.pdf?alt=media&token=2d3df646-5cd1-41f4-a117-3a3214e9543f "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO CONSEJOS MUNICIPALES DE
                    SALUD </a>
                </td>

              </tr>

              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong> COORDINACIÓN DE PROTECCIÓN CIVIL</strong>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10790G1AVISO%20DE%20PRIVACIDAD%20CORTO%20COORDINACION%20DE%20PROTECCI%C3%93N%20CIVIL.pdf?alt=media&token=2aa5630b-30b3-4e85-bdef-204ccdeb3686"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO COORDINACION DE PROTECCIÓN
                    CIVIL</a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10790G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20COORDINACION%20DE%20PROTECCION%20CIVIL.pdf?alt=media&token=a96cf690-71ba-4d09-857b-649ac1ee0f5f"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO COORDINACION DE PROTECCION
                    CIVIL</a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20PROTECCI%C3%93N%20CIVIL%20y%20BOMBEROS%20AUTL%C3%81N.pdf?alt=media&token=a7d29248-e1c8-4c5d-bd21-2e42d8091015"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL COORDINACION DE PROTECCION
                    CIVIL</a>
                </td>
              </tr>



              <tr>

                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>DIRECCION DE MEDIO AMBIENTE Y DESARROLLO SUSTENTABLE</strong>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13135G1AVISO%20DE%20PRIVACIDAD%20CORTO%20DE%20LA%20DIRECCION%20DE%20MEDIO%20AMBIENTE%20Y%20DESARROLLO%20SUSTENTABLE.pdf?alt=media&token=be301616-13fa-4c14-9f80-8cf0fa786c64"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO DE LA DIRECCION DE MEDIO AMBIENTE Y DESARROLLO SUSTENTABLE </a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13135G1AVISO%20DE%20PRIVACIDAD%20CORTO%20DE%20LA%20DIRECCION%20DE%20MEDIO%20AMBIENTE%20Y%20DESARROLLO%20SUSTENTABLE.pdf?alt=media&token=be301616-13fa-4c14-9f80-8cf0fa786c64"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO DE LA DIRECCION DE MEDIO AMBIENTE Y DESARROLLO SUSTENTABLE</a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10790G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20DIRECCIÓN%20DE%20DESARROLLO%20HUMANO%20Y%20SOCIAL.pdf?alt=media&token=c827461f-d823-4363-bbd7-cbbba570025d"
                    target="_blank" style="text-align: end;">VISO DE PRIVACIDAD INTEGRAL DE LA DIRECCION DE MEDIO AMBIENTE Y DESARROLLO SUSTENTABLE
                    Y
                    SOCIAL</a>
                </td>
              </tr>



              <tr>

                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>DIR. DESARROLLO HUMANO Y SOCIAL</strong>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10790G1AVISO%20DE%20PRIVACIDAD%20CORTO%20DIRECCI%C3%93N%20DESARROLLO%20HUMANO%20Y%20SOCIAL%20.pdf?alt=media&token=ae395c88-db51-4e13-88bb-b5f47c6a1970"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO DIRECCIÓN DESARROLLO HUMANO Y
                    SOCIAL </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10790G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20DIRECCI%C3%93N%20DESARROLLO%20HUMANO%20Y%20SOCIAL%20.pdf?alt=media&token=86cfcce4-244f-40e8-a542-9c0f4fb5df7a"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO DIRECCIÓN DESARROLLO
                    HUMANO
                    Y SOCIAL</a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20DE%20LA%20DIRECCI%C3%93N%20DE%20DESARROLLO%20HUMANO%20Y%20SOCIAL.pdf?alt=media&token=27865f04-cd0d-4a1b-9db2-3acc2295abfb"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL DIRECCIÓN DE DESARROLLO HUMANO
                    Y
                    SOCIAL</a>
                </td>
              </tr>

              <tr>

                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>DIR. DESARROLLO RURAL Y JEF. FOMENTO AGROPECUARIO</strong>
                  <br>
                  <a href="  https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10790G1AVISO%20DE%20PRIVACIDAD%20CORTO%20DIRECCI%C3%93N%20DESARROLLO%20RURAL.pdf?alt=media&token=9f8e6cf2-fa13-44a6-b0b5-b55439a2db1f"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO FOMENTO AGROPECUARIO </a>

                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10790G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20FOMENTO%20AGROPECUARIO.pdf?alt=media&token=2e96aa1b-967e-496d-8365-5d1a35349bba"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO FOMENTO AGROPECUARIO</a>

                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20DIRECCI%C3%93N%20DESARROLLO%20RURAL.pdf?alt=media&token=63ad7235-5071-4d26-9286-8d8db02509c4c"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL DIRECCIÓN DESARROLLO RURAL</a>
                    <br>
                    <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13140G1AVISO%20DE%20PRIVACIDAD%20CORTO%20DIRECCI%C3%93N%20DE%20DESARROLLO%20RURAL..pdf?alt=media&token=2d041a58-ced7-4eed-b8a0-754753138616"
                      target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO DIRECCIÓN DE DESARROLLO RURAL</a>
<br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13140G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20DIRECCI%C3%93N%20%20DE%20DESARROLLO%20RURAL..pdf?alt=media&token=712597b3-84ba-47d1-ba13-b0cdb0234fc6"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO DIRECCIÓN DE DESARROLLO
                    RURAL</a>

                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13140G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20JEFATURA%20DE%20FOMENTO%20AGROPECUARIO.pdf?alt=media&token=61cca898-efbc-43bd-9e21-92c5a85b1b6c"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL FOMENTO AGROPECUARIO</a>
                </td>
              </tr>
              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>DIR. OBRAS PUBLICAS</strong>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10790G1AVISO%20DE%20PRIVACIDAD%20CORTO%20DE%20LA%20DIRECCI%C3%93N%20DE%20OBRAS%20P%C3%9ABLICAS.pdf?alt=media&token=e61898a8-264a-455a-a669-713984aa181e"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO DE LA DIRECCIÓN DE OBRAS
                    PÚBLICAS</a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10790G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20DE%20LA%20DIRECCI%C3%93N%20DE%20OBRAS%20P%C3%9ABLICAS.pdf?alt=media&token=b110e14f-7f59-4eed-8ffe-82308d0eb0b5"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO DE LA DIRECCIÓN DE OBRAS
                    PÚBLICAS</a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10790G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20DE%20LA%20DIRECCIÓN%20DE%20OBRAS%20PÚBLICAS.pdf?alt=media&token=52ac8732-0c11-46c4-8dd8-4d13b44f9d3e"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL DE LA DIRECCIÓN DE OBRAS
                    PÚBLICAS</a>
                </td>
              </tr>
              <tr>

                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>DIRECCIÓN ARTE CULTURA Y EDUCACIÓN</strong>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10790G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20DE%20LA%20DIRECCI%C3%93N%20DE%20ARTE%2C%20CULTURA%20Y%20EDUCACI%C3%93N..pdf?alt=media&token=c1a54b15-7cae-4d13-aeb5-662a20dcb9ac"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO DE LA DIRECCIÓN DE OBRAS
                    PÚBLICAS</a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10790G1AVISO%20DE%20PRIVACIDAD%20CORTO%20DE%20LA%20DIRECCI%C3%93N%20DE%20ARTE%2C%20CULTURA%20Y%20EDUCACI%C3%93N.pdf?alt=media&token=992ad1c6-fb5f-4392-a939-3431448c3aab"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO DE LA DIRECCIÓN DE OBRAS
                    PÚBLICAS</a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20DE%20LA%20DIRECCI%C3%93N%20DE%20ARTE%2C%20CULTURA%20Y%20EDUCACI%C3%93N.pdf?alt=media&token=e82fecc2-5966-45e9-a0e3-ed64cb0dff21"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL DE LA DIRECCIÓN DE OBRAS
                    PÚBLICAS</a>
                </td>

              </tr>


              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>DIRECCIÓN DE CATASTRO</strong>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10793G1AVISO%20DE%20PRIVACIDAD%20CORTO%20DIRECCI%C3%93N%20CATASTRO.pdf?alt=media&token=4ffe8719-9a50-4c2a-9470-36fa95bfe151"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO DIRECCIÓN CATASTRO
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10793G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20DIRECCI%C3%93N%20CATASTRO.pdf?alt=media&token=8f6b66b0-7051-4a7f-88a8-a4c0c2374aa8"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO DIRECCIÓN CATASTRO
                    </a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20DIRECCI%C3%93N%20DE%20CATASTRO.pdf?alt=media&token=03ce8a85-8b20-4c04-ad62-f2d93b76bc0d"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL DIRECCIÓN DE CATASTRO
                    </a>
                </td>
              </tr>


              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>DIRECCIÓN DE PLANEACIÓN Y DESARROLLO ECONÓMICO</strong>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10793G1AVISO%20DE%20PRIVACIDAD%20CORTO%20DIRECCI%C3%93N%20DE%20PLANEACI%C3%93N%20Y%20DESARROLLO%20ECON%C3%93MICO.pdf?alt=media&token=3984d5fa-63b6-4c8b-998b-75900cee384b"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO DIRECCIÓN DE PLANEACIÓN Y DESARROLLO ECONÓMICO
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10793G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20DIRECCI%C3%93N%20DE%20PLANEACI%C3%93N%20Y%20DESARROLLO%20ECON%C3%93MICO.pdf?alt=media&token=f4b1d1ee-5275-4ed0-98f2-96c8de864c57"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO DIRECCIÓN DE PLANEACIÓN Y DESARROLLO ECONÓMICO
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10793G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20DIRECCI%C3%93N%20DE%20PLANEACI%C3%93N%20Y%20DESARROLLO%20ECON%C3%93MICO.pdf?alt=media&token=776a7770-6a31-44bc-8add-52ab40af7818
                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL DIRECCIÓN DE PLANEACIÓN Y DESARROLLO ECONÓMICO
                    </a>
                </td>
              </tr>




              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>DIRECCIÓN DE TURISMO</strong>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10793G1AVISO-DE-PRIVACIDAD-SIMPLIFICADO-DIRECCI%C3%93N%20TURISMO.pdf?alt=media&token=8eb41810-9851-4e5c-94bd-a0adf3bbdfdd"
                    target="_blank" style="text-align: end;">AVISO-DE-PRIVACIDAD-SIMPLIFICADO-DIRECCIÓN TURISMO
                    </a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10793G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20%20DIRECCI%C3%93N%20TURISMO.pdf?alt=media&token=5f167104-e7cf-4840-af9d-e586c1bbdf15"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL DIRECCIÓN TURISMO
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10793G1AVISO%20DE%20PRIVACIDAD%20CORTO%20%20DIRECCI%C3%93N%20DE%20TURISMO.pdf?alt=media&token=c2482ba8-069f-4d41-9cc7-5433da014876
                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO DIRECCIÓN DE TURISMO
                    </a>
                </td>
              </tr>



              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>DIRECCIÓN DEPORTES</strong>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10793G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20DIRECCI%C3%93N%20DE%20DEPORTES.pdf?alt=media&token=ff04c1f7-50a5-4f0e-9f94-77531ef918bd"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO DIRECCIÓN DE DEPORTES
                    </a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10793G1AVISO%20DE%20PRIVACIDAD%20CORTO%20DIRECCI%C3%93N%20DE%20DEPORTES.pdf?alt=media&token=603a80ca-7f94-45ba-8926-76d7902ee846"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO DIRECCIÓN DE DEPORTES
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20DE%20LA%20DIRECCI%C3%93N%20DE%20DEPORTES.pdf?alt=media&token=b4141947-96ec-4355-9f9d-4d82f388291

                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL DE LA DIRECCIÓN DE DEPORTES
                    </a>
                </td>
              </tr>




              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>DIRECCIÓN DESARROLLO URBANO</strong>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10793G1AVISO%20DE%20PRIVACIDAD%20CORTO%20DIRECCI%C3%93N%20DESARROLLO%20URBANO%20.pdf?alt=media&token=1454d55c-ed4d-4fee-aa2d-5057dc4b2b48"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO DIRECCIÓN DESARROLLO URBANO
                    </a>
                  <br>
                  <a href="  https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20DIRECCI%C3%93N%20%20DESARROLLO%20URBANO.pdf?alt=media&token=143fa528-6521-4e2e-80be-f0561079694f
                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL DIRECCIÓN DESARROLLO URBANO
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10793G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20DIRECCI%C3%93N%20DESARROLLO%20URBANO.pdf?alt=media&token=84b7cba7-de79-4d21-a140-01fc13028451

                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO DIRECCIÓN DESARROLLO URBANO
                    </a>
                </td>
              </tr>



              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>H. AYUNTAMIENTO DE AUTLÁN DE NAVARRO</strong>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10793G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20DEL%20H.%20AYUNTAMIENTO%20DE%20AUTLAN%20DE%20NAVARRO%20.pdf?alt=media&token=4cebb23e-ee59-401f-bc08-292c3b81652e"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO DEL H. AYUNTAMIENTO DE AUTLAN DE NAVARRO
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10793G1AVISO%20DE%20PRIVACIDAD%20CORTO%20AYUNTAMIENTO%20DE%20AUTLAN%20DE%20NAVARRO%20.pdf?alt=media&token=4a6e7be2-9eae-4921-9a8f-63f8202da866"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO AYUNTAMIENTO DE AUTLAN DE NAVARRO
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13140G1AVISO%20DE%20PRIVACIDAD%20GENERAL%20DEL%20H.%20AYUNTAMIENTO%20DE%20AUTLAN%20DE%20NAVARRO%2C%20JALISCO..pdf?alt=media&token=9c9d0cd0-f547-4caa-8c04-67f1bbda5775


                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD GENERAL DEL H. AYUNTAMIENTO DE AUTLAN DE NAVARRO, JALISCO
                    </a>
                </td>
              </tr>



              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>HACIENDA PÚBLICA MPAL</strong>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10793G1AVISO%20DE%20PRIVACIDAD%20CORTO%20DE%20LA%20HACIENDA%20MUNICIPAL.pdf?alt=media&token=9a9a163c-1f2b-451a-9e42-16c276017688"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO DE LA HACIENDA MUNICIPAL
                    </a>
                  <br>
                  <a href="  https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10793G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20HACIENDA%20MUNICIPAL.pdf?alt=media&token=5f989820-8d6d-4728-b528-4ad8a78dcef3"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO HACIENDA MUNICIPAL
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20DE%20LA%20HACIENDA%20MUNICIPAL%20DE%20AUTLAN%20DE%20NAVARRO%2C%20JALISCO.pdf?alt=media&token=b9525091-f319-4135-b017-61aa594d588a

                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL HACIENDA MUNICIPAL
                    </a>
                </td>
              </tr>


              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>INSTITUTO MPAL. DE ATENCIÓN A JOVENES</strong>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10793G1AVISO%20DE%20PRIVACIDAD%20CORTO%20INSTITUTO%20MUNICIPAL%20EN%20ATENCI%C3%93N%20A%20LA%20JUVENTUD.pdf?alt=media&token=0fb57f45-13eb-4bf3-94c3-d402e752bb49"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO INSTITUTO MUNICIPAL EN ATENCIÓN A LA JUVENTUD
                    </a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10793G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20INSTITUTO%20MUNICIPAL%20DE%20ATENCI%C3%93N%20A%20LA%20JUVENTUD.pdf?alt=media&token=3ca86b0b-fb5c-4762-ba44-4a912a3f43f1"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO INSTITUTO MUNICIPAL DE ATENCIÓN A LA JUVENTUD
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10793G1AVISO%20DE%20PRIVACIDAD%20INRTEGRAL%20INSTITUTO%20MUNICIPAL%20DE%20ATENCIÓN%20A%20LA%20JUVENTUD.pdf?alt=media&token=b270a37d-f11c-4ed3-bd74-5b1ae104a75d

                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INRTEGRAL INSTITUTO MUNICIPAL DE ATENCIÓN A LA JUVENTUD
                    </a>
                </td>
              </tr>


              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>JEF. ARCHIVO HISTÓRICO</strong>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10793G1AVISO%20DE%20PRIVACIDAD%20CORTO%20DE%20LA%20JEFATURA%20ARCHIVO%20HISTORICO%20%20%20MUNICIPAL.pdf?alt=media&token=7050af1c-156f-4b7c-a30d-07de19e7dfba"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO DE LA JEFATURA ARCHIVO HISTORICO MUNICIPAL
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10793G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20DE%20LA%20JEFATURA%20ARCHIVO%20HISTORICO%20MUNICIPAL.pdf?alt=media&token=b4a31ded-b618-4ce1-aeab-3fb8b6b9965f"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO DE LA JEFATURA ARCHIVO HISTORICO MUNICIPAL
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20%20DE%20LA%20JEFATURA%20ARCHIVO%20HISTORICO%20MUNICIPAL.pdf?alt=media&token=dfa0e95a-a626-4521-89c0-293081ff9f1e
                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL JEFATURA ARCHIVO HISTORICO
                    </a>
                </td>
              </tr>




              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>JEF. ATENCION NIÑAS, NIÑOS Y ADOLESCENTES</strong>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10793G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20JEFATURA%20DE%20ATENCI%C3%93N%20A%20NI%C3%91AS%2C%20NI%C3%91OS%20Y%20ADOLESCENTES.pdf?alt=media&token=2520fccc-0a09-48a0-afd9-810e522d576a"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO JEFATURA DE ATENCIÓN A NIÑAS, NIÑOS Y ADOLESCENTES
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10793G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20JEFATURA%20DE%20ATENCI%C3%93N%20A%20NI%C3%91AS%2C%20NI%C3%91OS%20Y%20ADOLESCENTES.pdf?alt=media&token=d373832f-c307-4c79-913a-bc36304bca53"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL JEFATURA DE ATENCIÓN A NIÑAS, NIÑOS Y ADOLESCENTES
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISOS%20DE%20PRIVACIDAD%20INTEGRAL%20JEFATURA%20DE%20PROTECCI%C3%93N%20A%20NI%C3%91AS%2C%20NI%C3%91AS%20Y%20ADOLESCENTES.pdf?alt=media&token=0b06bdd0-14fc-4d8b-804f-1098b461f779
                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO JEFATURA DE ATENCIÓN A NIÑAS, NIÑOS Y ADOLESCENTES
                    </a>
                </td>
              </tr>



              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>JEF. COMUNICACIÓN SOCIAL</strong>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10793G1AVISO%20DE%20PRIVACIDAD%20CORTO%20COMUNICACI%C3%93N%20SOCIAL%20.pdf?alt=media&token=f3e617ba-d6eb-4539-be91-cebb868400b8"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO COMUNICACIÓN SOCIAL
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20JEFATURA%20DE%20COMUNICACI%C3%93N%20SOCIAL.pdf?alt=media&token=1d7068f1-d723-44e0-a2b5-b36421f8225a"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL JEFATURA DE COMUNICACIÓN SOCIAL
                    </a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10793G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20JEFATURA%20COMUNICACI%C3%93N%20SOCIAL%20.pdf?alt=media&token=a1bb758b-b33c-4ed3-aefb-cd1bb63361fc

                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO JEFATURA COMUNICACIÓN SOCIAL
                    </a>
                </td>
              </tr>



              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>JEF. DE RECURSOS HUMANOS</strong>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10793G1AVISO%20DE%20PRIVACIDAD%20CORTO%20DE%20LA%20JEFATURA%20DE%20SERVICIOS%20GENERALES.pdf?alt=media&token=085ef614-1bdd-46fa-9e9d-3c1d665b5344"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO DE LA JEFATURA DE SERVICIOS GENERALES
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10793G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20DE%20LA%20JEFATURA%20DE%20SERVICIOS%20GENERALES%20.pdf?alt=media&token=81df564c-396d-4059-87e4-eb550773a400"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL DE LA JEFATURA DE SERVICIOS GENERALES
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10793G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20DE%20LA%20JEFATURA%20DE%20SERVICIOS%20GENERALES%20.pdf?alt=media&token=48695c7f-9ce3-4b77-bd95-a4f1f435baf4"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO DE LA JEFATURA DE SERVICIOS GENERALES
                    </a>
                </td>
              </tr>




              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>JEF. DE SERVICIOS GENERALES</strong>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20CORTO%20DE%20LA%20JEFATURA%20DE%20ESTACIONAMIENTOS.pdf?alt=media&token=9c51575d-153d-456a-abbd-14ac5844f080"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO DE LA JEFATURA DE SERVICIOS GENERALES
                    </a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1Aviso%20de%20Privacidad%20Integral%20de%20la%20Jefatura%20de%20Servicios%20Generales.pdf?alt=media&token=f834b3ba-3853-471b-9b57-30d1d7a5af43"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL DE LA JEFATURA DE SERVICIOS GENERALES
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20JEFATURA%20ESTACIONAMIENTOS.pdf?alt=media&token=8e4045bf-af1e-4f65-95c5-ae4b57dc22e9"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO DE LA JEFATURA DE SERVICIOS GENERALES
                    </a>
                </td>
              </tr>




              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>JEF. ESTACIONAMIENTOS</strong>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20CORTO%20DE%20LA%20JEFATURA%20DE%20ESTACIONAMIENTOS.pdf?alt=media&token=a605e811-a3b3-40cc-bd2d-88e241e2fc3f"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO DE LA JEFATURA DE ESTACIONAMIENTOS
                    </a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20ESTACIONAMIENTOS.pdf?alt=media&token=4d15793d-4297-47b4-9a99-d019150f9140"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO ESTACIONAMIENTOS
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20JEFATURA%20ESTACIONAMIENTOS.pdf?alt=media&token=74277c27-9411-4a0c-acca-ad8cfe20628f"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL JEFATURA ESTACIONAMIENTOS
                    </a>
                </td>
              </tr>


              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>JEF.OBRAS PUBLICAS</strong>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20CORTO%20DE%20LA%20JEFATURA%20DE%20OBRAS%20P%C3%9ABLICAS.pdf?alt=media&token=38d4a88a-7eb6-4bce-83d8-eaafdeb57d4c"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO DE LA JEFATURA DE OBRAS PÚBLICAS
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20JEFATURA%20OBRAS%20PUBLICAS.pdf?alt=media&token=8c343397-fe2f-4782-9952-f5399f7b2439"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO JEFATURA OBRAS PUBLICAS
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20DE%20LA%20JEFATURA%20DE%20OBRAS%20P%C3%9ABLICAS.pdf?alt=media&token=87696aae-49af-47ee-a6ed-59f4826f672f"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL DE LA JEFATURA DE OBRAS PÚBLICAS
                    </a>
                </td>
              </tr>



              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>JEF. PARTICIPACIÓN CIUDADANA</strong>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20CORTO%20PARTICIPACI%C3%93N%20CIUDADANA.pdf?alt=media&token=b058c8ff-35c3-43f1-a69e-41c75ec05199"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO PARTICIPACIÓN CIUDADANA
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20PARTICIPACION%20CIUDADANA.pdf?alt=media&token=c6ad5838-f2b5-43b0-a11a-99bddd3c147b"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO PARTICIPACION CIUDADANA
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20%20JEFATURA%20DE%20PARTICIPACI%C3%93N%20CIUDADANA.pdf?alt=media&token=4aebdc80-2dfe-41dc-94a4-344c2df1f21a"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL PARTICIPACION CIUDADANA
                    </a>
                </td>
              </tr>




              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>JEF. PROMOCIÓN ECONÓMICA</strong>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20CORTO%20JEFATURA%20PROMOCI%C3%93N%20ECON%C3%93MICA.pdf?alt=media&token=b23c4c95-a023-424d-868c-9f100fb917f8"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO JEFATURA PROMOCIÓN ECONÓMICA
                    </a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20JEFATURA%20PROMOCI%C3%93N%20ECON%C3%93MICA.pdf?alt=media&token=09ee6f24-bdc5-4522-aa5a-c1b0936cd2ef"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO JEFATURA PROMOCIÓN ECONÓMICA
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20JEFATURA%20PROMOCI%C3%93N%20ECON%C3%93MICA.pdf?alt=media&token=dc95613c-a21d-4f57-9f23-8dce600a3fbf
                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL JEFATURA PROMOCIÓN ECONÓMICA
                    </a>
                </td>
              </tr>





              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>JEF. PROMOCIÓN TURISTICA</strong>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13163G1AVISO%20DE%20PRIVACIDAD%20CORTO%20JEF.%20PROM.%20TUR%C3%8DSTICA%20-%20aqui.pdf?alt=media&token=abd96f62-639c-4128-a2f5-981186650d6e"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO JEF. PROM. TURÍSTICAA
                    </a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13163G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20%20JEF.%20PROM.%20TUR%C3%8DSTICA.pdf?alt=media&token=f5c5bf4c-0497-4da8-94a3-53b030bf08d3"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL JEF. PROM. TURÍSTICA
                    </a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13163G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20JEF.%20PROM.%20TUR%C3%8DSTICA.pdf?alt=media&token=d667b84d-1221-4a24-979b-a8318b9ca21b
                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO JEF. PROM. TURÍSTICA
                    </a>
                </td>
              </tr>




              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>JEF. TALLER MECANICO Y MODULO DE MAQUINARIA</strong>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20CORTO%20MODULO%20DE%20MAQUINARIA.pdf?alt=media&token=1bb4d47a-4a83-43a4-badd-9792dee46536"
                    target="_blank" style="text-align: end;"> AVISO DE PRIVACIDAD CORTO MODULO DE MAQUINARIA
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20JEFATURA%20TALLER%20MECANICO.pdf?alt=media&token=d4840153-01c6-4c29-b758-39faab70451b"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL JEFATURA TALLER MECANICO
                    </a>
                  <br>
                  <a href="  https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20JEFATURA%20TALLER%20MECANICO.pdf?alt=media&token=31193fb2-4b8b-41c6-958c-f4bdaa64e2d5
                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO JEFATURA TALLER MECANICO
                    </a>

                <br>
                <a href="  https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20MODULO%20DE%20MAQUINARIA.pdf?alt=media&token=6e1b3bed-aebe-492c-8106-48683c2fef5b
                "
                  target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL MODULO DE MAQUINARIA
                  </a>

              <br>
              <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20%20JEFATURA%20M%C3%93DULO%20DE%20MAQUINARIA%20.pdf?alt=media&token=63ffda10-f950-4668-890a-80c9658813a7
              "
                target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO JEFATURA MÓDULO DE MAQUINARIA
                </a>


              <br>
              <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20CORTO%20TALLER%20MECANICO.pdf?alt=media&token=d7ae5ebe-c9d4-488d-a1e8-b3330cb52b24
              "
                target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO TALLER MECANICO
                </a>
            </td>
              </tr>




              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>JEFATURA ADMINISTRATIVA SISTEMA MUNICIPAL DE AGUA,  ALCANTARILLADO Y SANEAMIENTOS</strong>
                  <br>
                  <a href="  https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20CORTO%20JEFATURA%20ADMINISTRATIVA%20SISTEMA%20MUNICIPAL%20DE%20AGUA%2C%20%20ALCANTARILLADO%20Y%20SANEAMIENTO.pdf?alt=media&token=7d96aff3-85b1-4f37-b6cb-cce636c11b08"
                    target="_blank" style="text-align: end;"> AVISO DE PRIVACIDAD CORTO JEFATURA ADMINISTRATIVA SISTEMA MUNICIPAL DE AGUA, ALCANTARILLADO Y SANEAMIENTO.
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20DIRECCI%C3%93N%20SISTEMA%20MUNICIPAL%20DE%20AGUA%2C%20%20ALCANTARILLADO%20Y%20SANEAMIENTO.pdf?alt=media&token=7643be5c-2c0b-4ea3-8dd6-359dcf673ee6"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL JEFATURA ADMINISTRATIVA SISTEMA MUNICIPAL DE AGUA, ALCANTARILLADO Y SANEAMIENTO
                    </a>
                  <br>
                  <a href="  https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20JEFATURA%20ADMINISTRATIVA%20SISTEMA%20MUNICIPAL%20DE%20AGUA%2C%20%20ALCANTARILLADO%20Y%20SANEAMIENTO.pdf?alt=media&token=b05cd722-306f-4fa1-9a3a-829f4f45fd8e
                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO JEFATURA ADMINISTRATIVA SISTEMA MUNICIPAL DE AGUA, ALCANTARILLADO Y SANEAMIENTO
                    </a>
                </td>
              </tr>



              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>JEFATURA ARTE Y CULTURAS</strong>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20JEFATURA%20DE%20ARTE%20Y%20CULTURA.pdf?alt=media&token=20bd586f-2926-45b2-b3e0-e7c7a0d3e815"
                    target="_blank" style="text-align: end;"> AVISO DE PRIVACIDAD INTEGRAL JEFATURA DE ARTE Y CULTURA
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20JEFATURA%20ARTE%20Y%20CULTURA.pdf?alt=media&token=341f36c2-0076-4951-b74d-781ff0d0ed01"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO JEFATURA ARTE Y CULTURA
                    </a>
                  <br>
                  <a href="  https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20CORTO%20JEFATURA%20ARTE%20Y%20CULTURA.pdf?alt=media&token=87b47351-6962-4cd5-ab4b-7f971f789ade
                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO JEFATURA ARTE Y CULTURA
                    </a>
                </td>
              </tr>


              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>JEF. REGLAMENTOS Y APREMIOS</strong>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20REGLAMENTOS%20Y%20APREMIOS.pdf?alt=media&token=8aa8afee-c442-4aea-95c9-7d9317dd1406"
                    target="_blank" style="text-align: end;"> AVISO DE PRIVACIDAD INTEGRAL REGLAMENTOS Y APREMIOS.
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20CORTO%20REGLAMENTOS%20Y%20APREMIOS.pdf?alt=media&token=15c57f56-80f4-45ae-b7cf-5f19bf335663"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO REGLAMENTOS Y APREMIOS
                    </a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20REGLAMENTOS%20Y%20APREMIOS.pdf?alt=media&token=5034d046-83a3-4fbf-aa3c-c8d7d47b270e
                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO REGLAMENTOS Y APREMIOS
                    </a>
                </td>
              </tr>


              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>JEFATURA OPERATIVA</strong>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20CORTO%20JEFATURA%20OPERATIVA%20SISTEMA%20MUNICIPAL%20DE%20AGUA%2C%20%20ALCANTARILLADO%20Y%20SANEAMIENTO.pdf?alt=media&token=edf6b7c9-8dfc-4ab2-a5aa-a98b9b655142"
                    target="_blank" style="text-align: end;"> AVISO DE PRIVACIDAD CORTO JEFATURA OPERATIVA SISTEMA MUNICIPAL DE AGUA, ALCANTARILLADO Y SANEAMIENTO
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20JEFATURA%20OPERATIVA%20SISTEMA%20MUNICIPAL%20DE%20AGUA%2C%20%20ALCANTARILLADO%20Y%20SANEAMIENTO.pdf?alt=media&token=c80443cc-5c35-4d65-9d38-1599d3d1cb1b"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL JEFATURA OPERATIVA SISTEMA MUNICIPAL DE AGUA, ALCANTARILLADO Y SANEAMIENTO
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20JEFATURA%20OPERATIVA%20SISTEMA%20MUNICIPAL%20DE%20AGUA%2C%20%20ALCANTARILLADO%20Y%20SANEAMIENTO.pdf?alt=media&token=89cb84bc-b9d8-43ff-ade7-fcac77a5792f
                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO JEFATURA OPERATIVA SISTEMA MUNICIPAL DE AGUA, ALCANTARILLADO Y SANEAMIENTO
                    </a>
                </td>
              </tr>

              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>JEFATURA SISTEMAS DIGITALES</strong>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20CORTO%20JEFATURA%20SISTEMAS%20DIGITALES%20.pdf?alt=media&token=7bf13e00-106d-4b56-903b-dc0be3062127"
                    target="_blank" style="text-align: end;"> AVISO DE PRIVACIDAD CORTO JEFATURA SISTEMAS DIGITALES
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20JEFATURA%20SISTEMAS%20DIGITALES.pdf?alt=media&token=ef82852f-c8c7-4932-bdd8-32d1079ba533"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL JEFATURA SISTEMAS DIGITALES
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20JEFATURA%20SISTEMAS%20DIGITALES.pdf?alt=media&token=7116f03a-a5fa-4843-ace8-6e715ccf6088
                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO JEFATURA SISTEMAS DIGITALES
                    </a>
                </td>
              </tr>


              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>JUZGADO MUNICIPAL</strong>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20CORTO%20JUZGADO%20MUNICIPAL.pdf?alt=media&token=4ee98321-fca4-4577-b449-92243c682436"
                    target="_blank" style="text-align: end;"> AVISO DE PRIVACIDAD CORTO JUZGADO MUNICIPAL
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20JUZGADO%20MUNICIPAL%20.pdf?alt=media&token=af1a1f59-be65-42c5-9409-9ec8e8bd7b9b"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL JUZGADO MUNICIPAL
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20JUZGADO%20MUNICIPAL%20.pdf?alt=media&token=111a6ed3-fdd1-4fbb-8742-d8544e6d8e56
                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO JUZGADO MUNICIPAL
                    </a>
                </td>
              </tr>


              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>AVISO DE PRIVACIDAD SIMPLIFICADO DE LA JEFATURA DE UNIDAD CENTRALIZADA DE COMPRAS</strong>
                  <br>
                  <a href="  https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20DE%20LA%20JEFATURA%20DE%20UNIDAD%20CENTRALIZADA%20DE%20COMPRAS%20.pdf?alt=media&token=ca2990f1-5e57-4e32-b32c-7dc3c665c2a9"
                    target="_blank" style="text-align: end;"> AVISO DE PRIVACIDAD SIMPLIFICADO DE LA JEFATURA DE UNIDAD CENTRALIZADA DE COMPRAS
                    </a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20DE%20LA%20JEFATURA%20DE%20UNIDAD%20CENTRALIZADA%20DE%20COMPRAS%20.pdf?alt=media&token=ac452fba-d3aa-43ef-ae53-5ba540d4a838"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL DE LA JEFATURA DE UNIDAD CENTRALIZADA DE COMPRAS
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20CORTO%20JEFATURA%20JEFATURA%20DE%20UNIDAD%20CENTRALIZADA%20DE%20COMPRAS.pdf?alt=media&token=64369d09-5405-4a60-be4b-53535d01b03c
                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO JEFATURA JEFATURA DE UNIDAD CENTRALIZADA DE COMPRAS
                    </a>
                </td>
              </tr>

              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>JEFATURA DE  MEDIO AMBIENTE</strong>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20INTEGRAl%20JEFATURA%20DE%20MEDIO%20AMBIENTE.pdf?alt=media&token=2528385c-6540-4282-8ac9-ac43f573a03a"
                    target="_blank" style="text-align: end;"> AVISO DE PRIVACIDAD INTEGRAl JEFATURA DE MEDIO AMBIENTE
                    </a>
                  <br>
                  <a href="  https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20JEFATURA%20DE%20MEDIO%20AMBIENTE.pdf?alt=media&token=5c5a1041-f6e8-43c7-b79c-a5437dfe699f"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO JEFATURA DE MEDIO AMBIENTE
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20CORTO%20JEFATURA%20DE%20MEDIO%20AMBIENTE.pdf?alt=media&token=1a573b4f-502f-451c-a5b2-83a5b5be1793
                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO JEFATURA MEDIO AMBIENTE
                    </a>
                </td>
              </tr>


              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>JEFATURA DE CENTRO DE HIGIENE URBANA VETERINARIA</strong>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20CORTO%20JEFATURA%20DE%20CENTRO%20DE%20HIGIENE%20URBANA%20VETERINARIA.pdf?alt=media&token=603e48fa-bb55-44ea-b174-987457281aef"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO JEFATURA DE HIGIENE URBANA VETERINARIA
                    </a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20JEFATURA%20DE%20CENTRO%20DE%20HIGIENE%20URBANA%20VETERINARIA.pdf?alt=media&token=09c95700-3c91-4aa0-9729-12ddcbe22526"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO JEFATURA DE CENTRO DE HIGIENE URBANA VETERINARIA
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20JEFATURA%20DE%20CENTRO%20DE%20HIGIENE%20URBANA%20Y%20VETERINARIA.pdf?alt=media&token=23c6219e-e282-43b2-90b1-c32b0fb0a53b

                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL JEFATURA DE CENTRO DE HIGIENE URBANA Y VETERINARIA
                    </a>
                </td>
              </tr>




              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>AVISO DE PRIVACIDAD CORTO JEFATURA DE EDUCACION PARA LA SUSTENTABILIDAD</strong>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20CORTO%20JEFATURA%20DE%20EDUCAION%20PARA%20LA%20SUSTENTABILIDAD.pdf?alt=media&token=ee5e8f4b-988e-44a4-8988-1931a142103a"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO JEFATURA DE EDUACAION PARA LA SUSTENTABILIDAD
                    </a>
                  <br>
                  <a href="  https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20JEFATURA%20DE%20EDUCACION%20PARA%20LA%20SUSTENTABILIDAD.pdf?alt=media&token=7f96489b-625e-4517-8f2e-13e66740ee4e"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO JEFATURA DE EDUACACION PARA LA SUSTENTABILIDAD
                    </a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20JEFATURA%20DE%20EDUCACION%20PARA%20LA%20SUSTENTABILIDAD%20.pdf?alt=media&token=4a645712-a2fc-4bf6-b248-b4f19991c2db

                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL JEFATURA DE EDUCACION PARA LA SUSTENTABILIDAD
                    </a>
                </td>
              </tr>



              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>JEFATURA DE PARQUES Y JARDINES</strong>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20JEFATURA%20DE%20PARQUES%20Y%20JARDINES.pdf?alt=media&token=9df84b95-4bf1-4343-8eef-b18ed5d097aa"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO JEFATURA DE PARQUES Y JARDINES
                    </a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20JEFATURA%20DE%20PARQUES%20Y%20JARDINES.pdf?alt=media&token=5835b409-98c4-4263-9ccb-fc96199363eb"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL JEFATURA PARQUES Y JARDINES
                    </a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20JEFATURA%20DE%20PARQUES%20Y%20JARDINES.pdf?alt=media&token=9df84b95-4bf1-4343-8eef-b18ed5d097aa

                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO JEFATURA DE PARQUES Y JARDINES
                    </a>
                </td>
              </tr>






              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>JEFATURA DE RECOLECCION DE RESIDUOS SOLIDOS</strong>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20CORTO%20JEFATURA%20DE%20RECOLECCION%20DE%20RESIDUOS%20S%C3%93LIDOS%20URBANOS.pdf?"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO JEFATURA DE RECOLECCIÓN DE RESIDUOS SÓLIDOS
                    </a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20JEFATURA%20DE%20RECOLECCION%20DE%20RESIDUOS.pdf?alt=media&token=61d335ac-cb61-4f44-b318-07017fe571a9"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL JEFATURA DE RECOLECCIÓN DE RESIDUOS SÓLIDOS
                    </a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20JEFATURA%20DE%20RECOLECCION%20DE%20RESIDUOS%20SOLIDOS%20URBANOS.pdf?alt=media&token=84196906-bac5-4015-bc23-89710dd7bc58

                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO JEFATURA DE RECOLECCIÓN DE RESIDUOS SÓLIDOS
                    </a>
                </td>
              </tr>


              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong> OFICIALÍA MAYOR DE SERVICIOS PÚBLICOS MUNICIPALES</strong>

<br>
                    <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20CORTO%20DE%20LA%20OFICIAL%C3%8DA%20MAYOR%20DE%20SERVICIOS%20P%C3%9ABLICOS%20MUNICIPALES.pdf?alt=media&token=9fa830c2-abdf-4740-9e66-781fb4229513"
                      target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO DE LA OFICIALÍA MAYOR DE SERVICIOS PÚBLICOS MUNICIPALES
                      </a>
                    <br>
                    <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20OFICIAL%C3%8DA%20MAYOR%20DE%20SERVICIOS%20P%C3%9ABLICOS%20MUNICIPALES..pdf?alt=media&token=9d8f7f03-0369-4bd5-9a97-002b9ea96fd8
                    "
                      target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL OFICIALIA MAYOR DE SERVICIOS PUBLICOS MUNICIPALES
                      </a>
                      <br>
                      <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20OFICIALIA%20MAYOR%20DE%20SERVICIOS%20PUBLICOS%20MUNICIPALES.pdf?alt=media&token=e2cd6652-dea9-4291-9108-53aab1ada92a
                      "
                        target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO OFICIALIA MAYOR DE SERVICIOS PUBLICOS MUNICIPALES
                        </a>
                </td>
              </tr>


              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>PLAZA DE TOROS</strong>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20CORTO%20PLAZA%20DE%20TOROS%20.pdf?alt=media&token=72866e16-0527-4d31-a5a2-825a32a3d0e5"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO PLAZA DE TOROS
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10811G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20PLAZA%20DE%20TOROS.pdf?alt=media&token=b7ec2dd6-91af-496b-a3a6-083a5edb32f9"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO PLAZA DE TOROS
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20PLAZA%20DE%20TOROS.pdf?alt=media&token=2170c12f-d70c-4b3f-bcce-7a3769df4b38


                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL PLAZA DE TOROS
                    </a>
                </td>
              </tr>


              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>ORGANO INTERNO DE CONTROL</strong>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10854G1AVISO%20DE%20PRIVACIDAD%20CORTO%20-%20DECLARACION%20PATRIMONIAL.pdf?alt=media&token=86daf64b-f08f-4278-89c6-269133ea8a12"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10854G1AVISO%20DE%20PRIVACIDAD%20CORTO%20PROCESO%20DE%20ENTREGA-RECEPCION.pdf?alt=media&token=a4e2e542-8b95-448d-8ffe-da0c0e8ceb54"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO PROCESO DE ENTREGA-RECEPCION
                    </a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10854G1AVISO%20DE%20PRIVACIDAD%20CORTO%20-%20INVESTIGACI%C3%93N%2C%20SUSTANCIACI%C3%93N%2C%20RESOLUCI%C3%93N%20DE%20FALTAS%20ADMINISTRATIVAS%20Y%20AUDITORIAS.pdf?alt=media&token=3b4ba79a-4206-44c8-92fa-4eca247293ad

                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO - INVESTIGACIÓN, SUSTANCIACIÓN, RESOLUCIÓN DE FALTAS ADMINISTRATIVAS Y AUDITORIAS
                    </a>
                    <br>
                    <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10854G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20-%20INVESTIGACIONES%2C%20SUBTANCIACION%2C%20RESOLUCION%20DE%20FALTAS%20ADMINISTRATIVAS%20Y%20AUDITORIAS%20(1).pdf?alt=media&token=01d2670e-b3c2-466f-9714-9102b3d3b9a0"
                      target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO - INVESTIGACIONES, SUBTANCIACION, RESOLUCION DE FALTAS ADMINISTRATIVAS Y AUDITORIAS
                      </a>
                    <br>
                    <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10854G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20-%20OFICILIA%20DE%20PARTES%20Y%20070.pdf?alt=media&token=77910a78-3494-4b7a-9f20-de1de1839c7a"
                      target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO - OFICILIA DE PARTES Y 070
                      </a>
                    <br>
                    <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10854G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20-%20ENTREGA%20RECEPCION.pdf?alt=media&token=4ced591d-36f2-4aed-b61b-e69680a8ae44

                    "
                      target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL - ENTREGA RECEPCION
                      </a>


                      <br>
                      <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10854G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20-%20OFICIALIA%20DE%20PARTES%20Y%20070%20(1).pdf?alt=media&token=1f13205c-83d7-4e33-9221-802cf7c5bb5c"
                        target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL - OFICIALIA DE PARTES Y 070
                        </a>
                      <br>
                      <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10854G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20-%20ENTREGA%20RECEPCION.pdf?alt=media&token=914b865d-e57c-4afe-bf44-9b9fb33bf02b"
                        target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO - ENTREGA RECEPCION
                        </a>
                      <br>
                      <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10854G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20-%20DECLARACION%20PATRIMONIAL%20(1).pdf?alt=media&token=ed018a08-616c-4be5-8519-ed08cbd7d6d4

                      "
                        target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO - DECLARACION PATRIMONIAL
                        </a>


                        <br>
                        <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10854G1AVISO%20DE%20PRIVACIDAD%20CORTO%20-%20OFICIALIA%20DE%20PARTES%20Y%20070.pdf?alt=media&token=a63f3d42-6cb1-4895-807f-ecb08c72213f"
                          target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO - OFICIALIA DE PARTES Y 070
                          </a>
                        <br>
                        <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10854G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20-%20INVESTIGACIONES%2C%20SUBTANCIACION%2C%20RESOLUCION%20DE%20FALTAS%20ADMINISTRATIVAS%20Y%20AUDITORIAS%20(1).pdf?alt=media&token=c90ebde0-efaa-49b0-a058-fafe68ffe03e"
                          target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL - INVESTIGACIONES, SUBTANCIACION, RESOLUCION DE FALTAS ADMINISTRATIVAS Y AUDITORIAS
                          </a>
                        <br>
                        <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10854G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20-%20DECLARACION%20PATRIMONIAL.pdf?alt=media&token=adc48dee-50a4-4fa5-b05e-c17899fbe09c

                        "
                          target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL - DECLARACION PATRIMONIAL
                          </a>

                </td>
              </tr>






              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>PRESIDENCIA MUNICIPAL</strong>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10854G1AVISO-DE-PRIVACIDAD-SIMPLIFICADO-PRESIDENCIA.pdf?alt=media&token=b86a6c3f-fa31-4560-b88e-67beb6043b2d"
                    target="_blank" style="text-align: end;">AVISO-DE-PRIVACIDAD-SIMPLIFICADO-PRESIDENCIA
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10854G1AVISO-DE-PRIVACIDAD-CORTO-PRESIDENCIA.pdf?alt=media&token=127fe886-4438-4656-bfb9-3b6ae7a2a82e"
                    target="_blank" style="text-align: end;">AVISO-DE-PRIVACIDAD-CORTO-PRESIDENCIA
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10854G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20PRESIDENCIA%20.pdf?alt=media&token=0bb6b24d-0c68-4410-bcb6-6360361f9e88

                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL PRESIDENCIA
                    </a>
                </td>
              </tr>


              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>RASTRO  MPAL</strong>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20ADMINISTRACI%C3%93N%20DEL%20RASTRO%20MUNICIPAL%20(2).pdf?alt=media&token=2ab27d0b-c51e-473a-958c-093844d6c6be"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL ADMINISTRACIÓN DEL RASTRO MUNICIPAL
                    </a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10854G1AVISO%20DE%20PRIVACIDAD%20CORTO%20ADMINISTRACI%C3%93N%20DE%20RASTRO%20MUNICIPAL.pdf?alt=media&token=e6d5d547-8012-4bc9-9022-77417f3d0e0d"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO ADMINISTRACIÓN DE RASTRO MUNICIPAL
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10854G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20RASTRO%20MUNICIPAL.pdf?alt=media&token=77e4225d-163e-42dd-9e2a-a4810e2ff9aa


                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO RASTRO MUNICIPAL
                    </a>
                </td>
              </tr>


              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>SALA DE REGIDORES</strong>
                  <br>
                  <a href=" hhttps://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20SALA%20DE%20REGIDORES.pdf?alt=media&token=dbba64fa-6448-4a00-8db2-136b761e0a85"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL SALA DE REGIDORES
                    </a>
                  <br>
                  <a href="  https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10854G1AVISO%20DE%20PRIVACIDAD%20CORTO%20SALA%20DE%20REGIDORES.pdf?alt=media&token=f39bc0f5-4d36-4f8f-830e-e1ee799407ac"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO SALA DE REGIDORES
                    </a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10854G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20SALA%20DE%20REGIDORES.pdf?alt=media&token=957167bf-ac9f-443e-b26e-5411a59a1562


                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO SALA DE REGIDORES
                    </a>
                </td>
              </tr>


              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>SECRETARIA GENERAL</strong>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10854G1AVISO%20DE%20PRIVACIDAD%20CORTO%20SECRETARIA%20GENERAL.pdf?alt=media&token=03ac9e9a-771e-436a-8ff0-a6563bf7bed8"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO SECRETARIA GENERAL
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10854G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20SECRETARIA%20GENERAL.pdf?alt=media&token=5eef9ccb-c2d7-4709-b82c-7d37d8b579f5"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO SECRETARIA GENERAL
                    </a>
                  <br>
                  <a href="  https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13167G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20SECRETARIA%20GENERAL.pdf?alt=media&token=7ca84249-4a33-4634-af9e-4ce72ae439a8


                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL SECRETARIA GENERAL
                    </a>
                </td>
              </tr>


              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>SERVICIOS MED. MUNICIPALES</strong>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10854G1AVISO%20DE%20PRIVACIDAD%20CORTO%20SERVICIOS%20MEDICOS%20MUNICIPALES.pdf?alt=media&token=13aef49a-898f-438a-aeba-4d81f0eed6bd"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO SERVICIOS MEDICOS MUNICIPALES
                    </a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10854G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20SERVICIOS%20MEDICOS%20MUNICIPALES.pdf?alt=media&token=1f40d0ca-9960-4511-8da9-13d656ecae61"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO SERVICIOS MEDICOS MUNICIPALES
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10854G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20SERVICIOS%20MEDICOS%20MUNICIPALES.pdf?alt=media&token=fa8e66ed-e698-4e35-837b-255b9644488e


                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL SERVICIOS MEDICOS MUNICIPALES
                    </a>
                </td>
              </tr>








              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>SISTEMA MUNICIPAL DE AGUA,  ALCANTARILLADO Y SANEAMIENTO</strong>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10854G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20SISTEMA%20MUNICIPAL%20DE%20AGUA%2C%20%20ALCANTARILLADO%20Y%20SANEAMIENTO.pdf?alt=media&token=00a6b9ff-c0b3-4ddf-bf3a-1cc61c43790e"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL SISTEMA MUNICIPAL DE AGUA, ALCANTARILLADO Y SANEAMIENTO
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10854G1AVISO%20DE%20PRIVACIDAD%20CORTO%20SISTEMA%20MUNICIPAL%20DE%20AGUA%2C%20%20ALCANTARILLADO%20Y%20SANEAMIENTO.pdf?alt=media&token=6e286681-7e11-46f6-97d2-3d4aceb5c8f1"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO SISTEMA MUNICIPAL DE AGUA, ALCANTARILLADO Y SANEAMIENTO
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10854G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20SISTEMA%20MUNICIPAL%20DE%20AGUA%2C%20%20ALCANTARILLADO%20Y%20SANEAMIENTO.pdf?


                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO SISTEMA MUNICIPAL DE AGUA, ALCANTARILLADO Y SANEAMIENTO
                    </a>
                </td>
              </tr>




              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>UNIDAD DE TRANSPARENCIA Y ACCESO A LA INFORMACIÓN</strong>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13136G1AVISO%20DE%20PRIVACIDAD%20CORTO%20AVISO%20DE%20PRIVACIDAD%20CORTO%20UNIDAD%20DE%20TRANSPARENCIA%20Y%20ACCESO%20A%20LA%20INFORMACI%C3%93N.pdf?alt=media&token=768b581f-cca1-46d3-9269-08330a18ed7c
                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO UNIDAD DE TRANSPARENCIA
                    </a>
                  <br>
                  <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10854G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20UNIDAD%20DE%20TRANSPARENCIA%20.pdf?alt=media&token=3dd82288-8762-4f6c-a965-06c4a88eb233"
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO UNIDAD DE TRANSPARENCIA
                    </a>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13635G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20UNIDAD%20DE%20TRANSPARENCIA%20Y%20ACCESO%20A%20LA%20INFORMACI%C3%93N.pdf?alt=media&token=04284ec8-1c89-46e6-982f-caa4b579661a


                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL UNIDAD DE TRANSPARENCIA
                    </a>
                </td>
              </tr>


              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong> JEFATURA DE INCLUSIÓN SOCIAL Y ATENCIÓN A PERSONASCON CAPACIDADES DIFERENTES</strong>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13136G1AVISO%20DE%20PRIVACIDAD%20CORTO%20DE%20LA%20JEFATURA%20DE%20INCLUSI%C3%93N%20SOCIAL%20Y%20ATENCI%C3%93N%20A%20PERSONASCON%20CAPACIDADES%20DIFERENTES..pdf?alt=media&token=6ab5c03b-ac3a-4104-a170-b8ae3289bc46
                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO DE LA JEFATURA DE INCLUSIÓN SOCIAL Y ATENCIÓN A PERSONASCON CAPACIDADES DIFERENTES
                    </a>
                    <br>
                    <a href="  https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13138G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20DE%20LA%20JEFATURA%20DE%20INCLUSI%C3%93N%20SOCIAL%20Y%20ATENCI%C3%93N%20A%20PERSONAS%20CON%20CAPACIDADES%20DIFERENTES..pdf?alt=media&token=190fda91-e183-4248-9d1c-598e1b9f9ef9
                    "
                      target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO DE LA JEFATURA DE INCLUSIÓN SOCIAL Y ATENCIÓN A PERSONAS CON CAPACIDADES DIFERENTES
                      </a>
                      <br>
                      <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13138G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20DE%20LA%20JEFATURA%20DE%20INCLUSI%C3%93N%20SOCIAL%20Y%20ATENCI%C3%93N%20A%20PERSONASCON%20CAPACIDADES%20DIFERENTES..pdf?alt=media&token=979ff446-fb10-4179-be1c-cd9c0e96cac3

                      "
                        target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL DE LA JEFATURA DE INCLUSIÓN SOCIAL Y ATENCIÓN A PERSONASCON CAPACIDADES DIFERENTES
                        </a>

                </td>
              </tr>


              <tr>
                <td>
                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                  <strong>  SINDICATURA Y DIRECCION JURIDICA DE AUTLÁN DE NAVARRO, JALISCO</strong>
                  <br>
                  <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13139G1AVISO%20DE%20PRIVACIDAD%20CORTO%20DE%20LA%20SINDICATURA%20Y%20DIRECCION%20JURIDICA%20DE%20AUTL%C3%81N%20DE%20NAVARRO%2C%20JALISCO..pdf?alt=media&token=008b6032-dbd8-42d8-b42f-21fa9ca45639
                  "
                    target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD CORTO DE LA SINDICATURA Y DIRECCION JURIDICA DE AUTLÁN DE NAVARRO, JALISCO.
                    </a>
                    <br>
                    <a href=" https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13139G1AVISO%20DE%20PRIVACIDAD%20SIMPLIFICADO%20DE%20LA%20SINDICATURA%20Y%20DIRECCION%20JURIDICA%20DE%20AUTL%C3%81N%20DE%20NAVARRO%2C%20JALISCO..pdf?alt=media&token=ec947dfb-b37a-48c7-8143-c72b37efb470
                    "
                      target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD SIMPLIFICADO DE LA SINDICATURA Y DIRECCION JURIDICA DE AUTLÁN DE NAVARRO, JALISCO
                      </a>
                      <br>
                      <a href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR13139G1AVISO%20DE%20PRIVACIDAD%20INTEGRAL%20DE%20LA%20SINDICATURA%20Y%20DIRECCION%20JURIDICA%20DE%20AUTL%C3%81N%20DE%20NAVARRO%2C%20JALISCO..pdf?alt=media&token=eb781198-cbf6-4836-b2f5-869040d93300


                      "
                        target="_blank" style="text-align: end;">AVISO DE PRIVACIDAD INTEGRAL DE LA SINDICATURA Y DIRECCION JURIDICA DE AUTLÁN DE NAVARRO, JALISCO
                        </a>

                </td>
              </tr>


            </tbody>
          </table>
        </div>
      </div>
    </section>

  </main><!-- End #main -->

  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
