import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RespuestaAPI } from "src/interface/api-responses.model";
import { environment } from 'src/environments/environment';
import { Observable, Subject } from "rxjs";


const API = "https://api.guarderia.saion.mx";

@Injectable({
  providedIn: 'root'
})

export class Fraccion1Service{

  constructor(private http:HttpClient){}

  //---Observable para indicar URL de foto obtenida---
  urlFoto: any;
  private subject = new Subject<any>();
  url: string = API + "/";


  obtenerA82018(id: any, inciso: any, ano:any, carpeta:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/docs/A8/${id}/${inciso}/${ano}/${carpeta}`);
  }

  obtenerA8MES(id: any, inciso: any, ano:any, mes:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/docs/${id}/${inciso}/${ano}/${mes}`);
  }

  obtenerA8Carpeta(id: any, inciso: any, carpeta:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/docs/A8/${id}/${inciso}/${carpeta}`);
  }
  obtenerA8año(id: any, inciso: any, ano:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/doc/A8/ano/${id}/${inciso}/${ano}`);
  }
  obtenerAreas(id:any, inciso:any, ano:any, area:any){
    return this.http.get<RespuestaAPI>(API +`/autlan/areas/${id}/${inciso}/${ano}/${area}`);
  }
  obtenerarpetaMes(id: any, inciso: any, ano:any, mes:any, carpeta:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/bienes/${id}/${inciso}/${ano}/${mes}/${carpeta}`);
  }

  obtenertrimestres(id: any, inciso: any, ano:any, area:any, carpeta:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/docs/A86L/${id}/${inciso}/${ano}/${area}/${carpeta}`);
  }

  obtenerf13(id: any, inciso: any, area:any, carpeta:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/docs/A813/${id}/${inciso}/${area}/${carpeta}`);
  }
  obtenerA152018(id: any, inciso: any, ano:any, carpeta:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/dato/A15/${id}/${inciso}/${ano}/${carpeta}`);
  }
  obtenerA15MES(id: any, inciso: any, ano:any, mes:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/A15/mes/${id}/${inciso}/${ano}/${mes}`);
  }

  obtenerA15Carpeta(id: any, inciso: any, carpeta:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/dato/A15/${id}/${inciso}/${carpeta}`);
  }
  obtenerA15año(id: any, inciso: any, ano:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/ano/${id}/${inciso}/${ano}`);
  }
  obtener15Areas(id:any, inciso:any, ano:any, area:any){
    return this.http.get<RespuestaAPI>(API +`/autlan/A15/${id}/${inciso}/${ano}/${area}`);
  }
  obtener15arpetaMes(id: any, inciso: any, ano:any, mes:any, carpeta:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/A15/${id}/${inciso}/${ano}/${mes}/${carpeta}`);
  }

  obtener15trimestres(id: any, inciso: any, ano:any, area:any, carpeta:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/docs/A15/${id}/${inciso}/${ano}/${area}/${carpeta}`);
  }

  obtenerf15(id: any, inciso: any, area:any, carpeta:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/docs/A15/${id}/${inciso}/${area}/${carpeta}`);
  }

  obtenerNoticia(id: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/obtenerNoticia/${id}`);
  }

  porPersona(id:any, anio: any){
    return this.http.get<RespuestaAPI>(API +`/autlan/fraccionV/${id}/${anio}`);
  }





















}
