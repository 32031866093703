<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <meta content="" name="description">
  <meta content="" name="keywords">

  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

</head>

<body>
  <!--Main layout-->
  <main style="margin-top: 40px;">
    <div class="container pt-4"></div>
  </main>
  <!--Main layout-->

  <section id="blog" class="blog" style="margin-top: 50px;">
    <div class="section-title" data-aos="fade-up">
      <h4>Cargar documento:</h4>
      <div class="fondo" data-aos="fade-up">
        <div style="justify-content:center; text-align:center;">
          <form [formGroup]="documentoForm">
            <br><label style="margin-top: 2%; margin-right: 1%; font-weight: bold;">Base:</label>
            <select style="width: 90%;" formControlName="NOMBRE" style="border-radius: 1vh;">


              <option value=" 1">ADAN MERCADO BALLARDO</option>
              <option value=" 2">ADRIAN ANCIRA MEDINA</option>
              <option value=" 3">ADRIAN MARTINEZ GUERRA</option>
              <option value=" 4">ADRIANA GONZALEZ BUENO</option>
              <option value=" 5">ADRIANA ISABEL MEDINA ARANA</option>
              <option value=" 6">AGUSTIN CARRILLO RODRIGUEZ</option>
              <option value=" 7">AGUSTIN GUTIERREZ GONZALEZ</option>
              <option value=" 8">AGUSTIN RODRIGUEZ PEÑA</option>
              <option value=" 9">ALDO VARGAS PELAYO</option>
              <option value=" 10">ALEJANDRA GUADALUPE CASTILLO BRAVO</option>
              <option value=" 11">ALEJANDRA MIRIAM ANDRADE ANDRADE</option>
              <option value=" 12">ALEJANDRA OLVERA MALDONADO</option>
              <option value=" 13">ALEJANDRO FLORES DE NIZ</option>
              <option value=" 14">ALEJANDRO GOMEZ</option>
              <option value=" 15">ALEJANDRO GUTIERREZ GONZALEZ</option>
              <option value=" 16">ALEJANDRO RAMON GOMEZ LLAMAS</option>
              <option value=" 17">ALEXIS EDUARDO QUINTERO OCHOA </option>
              <option value=" 18">ALEXIS GARCIA DIAZ</option>
              <option value=" 19">ALFREDO AGUILAR RODRIGUEZ</option>
              <option value=" 20">ALFREDO ESCALERA PEÑUELAS</option>
              <option value=" 21">ALFREDO NICANOR ENRIQUEZ CONTRERAS</option>
              <option value=" 22">ALFREDO VAZQUEZ MUÑOZ</option>
              <option value=" 23">ALICIA BLANCAS MONDRAGON</option>
              <option value=" 24">ALICIA SOCORRO RAMIREZ MORALES</option>
              <option value=" 25">ALMA GLORIA VIDRIO CASTILLO</option>
              <option value=" 26">ALMA LUISA RODRIGUEZ RUIZ</option>
              <option value=" 27">ALONDRA DEL ROCIO NUÑEZ ZAVALZA</option>

              <option value=" 1001">AMADOR MARIA BRAYAN</option>
              <option value=" 29">AMBROSIO ARAIZA ROLON</option>
              <option value=" 30">ANA LILIA COVARRUBIAS ROBLES</option>
              <option value=" 31">ANA LIZBETH MORAN VARGAS</option>
              <option value=" 32">ANA LIZBETH MORAN VARGAS </option>
              <option value=" 33">ANA MARIA PEÑA GONZALEZ </option>
              <option value=" 34">ANA ROSA CASILLAS DAVILA</option>
              <option value=" 35">ANDREA ALEJANDRA GRANADOS GALLARDO </option>
              <option value=" 36">ANDREA CITLLALLY PRADO NAVA </option>
              <option value=" 37">ANDREA ESTEPHANIA REYNOSO GUTIERREZ </option>
              <option value=" 38">ANDREA JAQUELINE LEON CORONA </option>
              <option value=" 39">ANDRES CHAVEZ LEAL</option>
              <option value=" 40">ANDRES SANTIAGO RICO SANTANA</option>
              <option value=" 41">ANGEL AMADOR SOTO</option>
              <option value=" 42">ANGEL HERNANDEZ NUÑES</option>
              <option value=" 43">ANGEL ULISES BUCIO RUIZ</option>
              <option value=" 44">ANGELICA ELIZABETH GOMEZ AGUILAR </option>

              <option value=" 1002">ANGELICA MARIA GOMEZ VIDRIO </option>
              <option value=" 45">ANITA PULGARIN PRUDENCIO</option>
              <option value=" 46">ANTONIO AMBRIZ CERVANTES</option>
              <option value=" 47">ANTONIO ESPINOSA OLVERA</option>
              <option value=" 48">ANTONIO FLORES SOTO</option>
              <option value=" 49">ANTONIO LEON GARCÍA </option>
              <option value=" 50">ANTONIO RAMOS ARCINIEGA</option>
              <option value=" 51">ANTONIO VARGAS DELGADILLO</option>

              <option value=" 1003">ARMANDO ARAIZA ALVAREZ</option>
              <option value=" 52">ARMANDO RAFAEL VILLAFAÑA LÓPEZ </option>
              <option value=" 53">ARMANDO VILLAFAÑA GARCIA</option>
              <option value=" 54">ARTURO GALVAN GUERRERO</option>
              <option value=" 55">ARTURO HUERTA RODRIGUEZ</option>
              <option value=" 56">ARTURO RUBEN GONZALEZ GONZALEZ</option>
              <option value=" 57">BEATRIZ RAMOS MONTES</option>
              <option value=" 58">BEATRIZ RODRIGUEZ GONZALEZ</option>
              <option value=" 59">BERNARDO ROBLES TAPIA</option>
              <option value=" 60">BRENDA ALICIA PARTIDA PAZ</option>
              <option value=" 61">BRENDA ELIZABETH SOLORZANO BARRAGAN</option>
              <option value=" 62">CANDELARIO EZEQUIEL CARRANZA JIMENEZ</option>
              <option value=" 63">CARLOS ALBERTO JIMENEZ RAMOS</option>
              <option value=" 64">CARLOS ALBERTO OLGUIN RUIZ</option>
              <option value=" 65">CARLOS ALBERTO RIOS LOPEZ</option>
              <option value=" 66">CARLOS CLEMENTE SANDOVAL WUOTTO</option>
              <option value=" 67">CARLOS EDUARDO DIAZ ENRIQUEZ</option>
              <option value=" 68">CARLOS EDUARDO RAMIREZ CARRIZALES</option>
              <option value=" 69">CARLOS EMMANUEL GARCIA IBARRA</option>
              <option value=" 70">CARLOS IGNACIO CASTELLON LÓPEZ </option>
              <option value=" 71">CARMEN MARICELA JIMENEZ IBARRA</option>
              <option value=" 72">CELIA AGUILAR REYES</option>
              <option value=" 73">CESAR AARON ESCALERA ORTEGA</option>
              <option value=" 74">CESAR EDUARDO HORTA HERNANDEZ</option>
              <option value=" 75">CINTHIA JANET SOTO DIAZ </option>
              <option value=" 76">CLAUDIA ROCIO GONZALEZ GONZALEZ</option>
              <option value=" 77">CRESCENCIANO LOMELI MARTINEZ</option>
              <option value=" 78">CRISTINA FLORES SOTO </option>
              <option value=" 79">CRUZ MICAELA AMBRIZ MARTINEZ</option>
              <option value=" 80">DALIA ELIZABETH PELAYO GALINDO </option>
              <option value=" 81">DANIEL AMARAL HERNANDEZ</option>
              <option value=" 82">DANIEL FLORES DEL CASTILLO</option>
              <option value=" 83">DANIEL MEJIA TERRIQUEZ</option>
              <option value=" 84">DAVID ALONSO GALARZA</option>
              <option value=" 85">DENIS DEL SOCORRO MICHEL DECENA </option>
              <option value=" 86">DOMINGO ISRAEL HUERTA PELAYO</option>
              <option value=" 87">EDGAR ALEXANDER FLORES SENTENO </option>
              <option value=" 88">EDGAR ALONSO ANDRADE JOYA </option>
              <option value=" 89">EDGAR BELTRAN RAMIREZ</option>
              <option value=" 90">EDGAR DE JESUS JINEZ AGUILAR</option>
              <option value=" 91">EDGAR EDUARDO MORAN VARGAS</option>
              <option value=" 92">EDGAR EMANUEL COBIAN CISNEROS</option>
              <option value=" 93">EDGAR RENE RUIZ RODRIGUEZ</option>
              <option value=" 94">EDITH MERCEDES RAYAS URIBE </option>
              <option value=" 95">EDUARDO GUZMAN VIDRIALES</option>
              <option value=" 96">EDWIN FABIAN MARTINEZ RIVERA</option>
              <option value=" 97">EDWIND ANTONIO BARAJAS RIOS</option>
              <option value=" 98">ELIZABETH ALDACO RODRIGUEZ</option>
              <option value=" 99">EMILIA GARCIA XOCHIHUA</option>
              <option value=" 100">EMMA SOLORZANO BARAJAS</option>
              <option value=" 101">EMMANUEL BUCIO HORTA</option>
              <option value=" 102">ENRIQUE EFRAIN MENDEZ GARCIA</option>
              <option value=" 103">ERIC PELAYO ZUAZO</option>
              <option value=" 104">ERIKA GARCIA NUÑEZ</option>
              <option value=" 105">ERIKA LIZBETH GARCÍA PEREZ </option>
              <option value=" 106">ERNESTO ALONSO VARGAS MONTES</option>
              <option value=" 107">ERNESTO FLORES AVILA</option>
              <option value=" 108">ERNESTO FRANCISCO REYES VIRAMONTES</option>
              <option value=" 109">ESPERANZA ROSALES BARAJAS</option>
              <option value=" 110">ESTELA ARECHIGA GUZMAN</option>
              <option value=" 1004">ESTELA GARCIA ZAVALZA</option>

              <option value=" 111">ESTHER ALEJANDRA ARREOLA PELAYO </option>
              <option value=" 112">ESTHER BERENICE ARECHIGA NUÑEZ</option>
              <option value=" 113">EVA FLORES RODRIGUEZ</option>
              <option value=" 114">EVA IJAEL HERNANDEZ BARTOLO</option>
              <option value=" 115">EVA JOSEFINA BENAVIDES HERNANDEZ</option>
              <option value=" 116">EVELYN ITZEL PEREZ RAMOS </option>
              <option value=" 117">EVERARDO BLANCO HERNANDEZ</option>
              <option value=" 118">FABIOLA YANETT ESPINOZA CALLEROS</option>
              <option value=" 119">FAUSTO AGUILAR DE LA CRUZ</option>
              <option value=" 120">FAUSTO CHRISTIAN MICHEL RAMIREZ</option>
              <option value=" 121">FAUSTO MANCILLA MARTINEZ</option>
              <option value=" 122">FELIPE DE JESUS HERNANDEZ VELOZ</option>
              <option value=" 123">FELIPE DE JESUS VALENCIA ZAMORA </option>
              <option value=" 124">FELIX OCTAVIANO ACEVES GUERRERO</option>
              <option value=" 125">FERNANDO MENDEZ SANDOVAL</option>
              <option value=" 1005">FIDELA ALVARADO OLMEDO</option>

              <option value=" 126">FLORENTINA SUAREZ GARFIAS</option>
              <option value=" 127">FRANCISCO FREGOSO CASTRO</option>
              <option value=" 128">FRANCISCO GUEVARA LEAL</option>
              <option value=" 129">FRANCISCO HERNANDEZ NAVARRO</option>
              <option value=" 130">FRANCISCO ISRAEL REYNA VAZQUEZ</option>
              <option value=" 131">FRANCISCO JAVIER GARAY DIAZ</option>
              <option value=" 132">FRANCISCO JAVIER GUZMAN ALVAREZ</option>
              <option value=" 133">FRANCISCO JAVIER RAMIREZ TOSCANO</option>
              <option value=" 134">FRANCISCO JAVIER SALAZAR MORA</option>
              <option value=" 135">FRANCISCO JOAQUIN MADERA LEON</option>
              <option value=" 136">FRANCISCO RAFAEL DIAZ CORONA</option>
              <option value=" 137">GABRIEL JAIME ROBLEDO</option>
              <option value=" 138">GABRIELA SOLORZANO BAUTISTA </option>
              <option value=" 139">GENARO GABRIEL DE LA CRUZ</option>
              <option value=" 140">GENOVEVA SAMARA CRUZ SOLORZANO </option>
              <option value=" 141">GEORGINA BEATRIZ PALOMERA REGALADO</option>
              <option value=" 142">GERARDO HERNANDEZ FIGUEROA</option>
              <option value=" 143">GERARDO PAEZ ALVAREZ</option>
              <option value=" 144">GERARDO SEBASTIAN GONZALEZ SANCHEZ</option>
              <option value=" 145">GERARDO VILLANUEVA NARANJO</option>
              <option value=" 146">GISELLE EDITH JIMENEZ MERCADO </option>
              <option value=" 147">GLENDY PATRICIA AMEZQUITA FLORES</option>
              <option value=" 148">GLORIA RODRIGUEZ ROBLES</option>
              <option value=" 149">GONZALO ABRAHAM ARVIZU ZEPEDA </option>
              <option value=" 150">GREGORIO PEDROZA MARDUEÑO</option>
              <option value=" 151">GRISELDA EDITH CORTES RIVERA</option>
              <option value=" 152">GUADALUPE BUCIO BOYZO</option>
              <option value=" 153">GUILLERMO ANTONIO RAYAS GUTIERREZ</option>
              <option value=" 154">GUSTAVO ADOLFO RIVERA LLAMAS</option>
              <option value=" 155">HECTOR BERNARDO FRANCO GONZALEZ</option>
              <option value=" 156">HECTOR HUGO SANTANA RODRIGUEZ</option>
              <option value=" 157">HECTOR MANUEL SALCEDO VERA</option>
              <option value=" 158">HECTOR MARTINEZ RUELAS</option>
              <option value=" 159">HILARIO GENARO LOPEZ GONZALEZ</option>
              <option value=" 160">HORTENSIA LOPEZ GUZMAN</option>
              <option value=" 161">HUGO EDUARDO CORONA MENDEZ</option>
              <option value=" 162">HUGO LEON MENDOZA</option>
              <option value=" 163">HUMBERTO MARTIN MARTINEZ DIAZ</option>
              <option value=" 164">IRENE BERNABE RAMOS</option>
              <option value=" 165">IRMA GARCIA BUCIO</option>
              <option value=" 166">IRMA LORENA PELAYO CISNEROS</option>
              <option value=" 167">IRMA YESENIA ANCIRA ALVARADO</option>
              <option value=" 168">ISIDRO MALDONADO VILLA</option>
              <option value=" 169">ISMAEL CERVANTES LARIOS</option>
              <option value=" 170">ISMAEL MENDOZA AZUELA</option>
              <option value=" 171">ISMAEL SOTO NAVARRO</option>
              <option value=" 172">IVAN ALEJANDRO VARGAS MONTES</option>
              <option value=" 173">IVAN RENE GONZALEZ ZUÑIGA</option>
              <option value=" 174">IVAN ROGELIO TORTOLEDO VERDUZCO</option>
              <option value=" 175">J. JESUS FLORES GUTIERREZ</option>
              <option value=" 176">J JESUS PEREZ MACIAS</option>
              <option value=" 177">JAIME ARREOLA ALFARO</option>
              <option value=" 178">JAIME FLORES DEL CASTILLO</option>
              <option value=" 179">JAIME GABINO GOMEZ ACOSTA</option>
              <option value=" 180">JAIME LOPEZ GUTIERREZ</option>
              <option value=" 181">JAIME MORENO LOPEZ</option>
              <option value=" 182">JAIME SUAREZ ANCIRA</option>
              <option value=" 183">JAIME VIZCARRA</option>
              <option value=" 184">JANETH ALEXANDRA MANCILLA CASTILLO</option>
              <option value=" 185">JARUMI MARLITH ALMEJO REYES</option>
              <option value=" 186">JAVIER FUENTES BERNAL</option>
              <option value=" 187">JAVIER PALOMAR MORALES</option>
              <option value=" 188">JAVIER RODRIGUEZ CARDENAS</option>
              <option value=" 189">JAVIER ZAMORA SANCHEZ</option>
              <option value=" 190">JESUS ALFREDO RUIZ FASUTO</option>
              <option value=" 191">JESUS ALVAREZ ZAMORA</option>
              <option value=" 192">JESUS BARAJAS GALVAN</option>
              <option value=" 193">JESUS JULIAN RODRIGUEZ SOLORZANO </option>
              <option value=" 194">JESUS MARTIN LOPEZ SANTOS</option>
              <option value=" 195">JESUS SUAREZ MENDOZA</option>
              <option value=" 196">JESUS VILLALOBOS CARRILLO</option>
              <option value=" 197">JOAQUIN IVAN MEDINA QUINTERO </option>
              <option value=" 198">JOEL GALVAN PELAYO </option>
              <option value=" 199">JOEL RENE ROMERO CORONA</option>
              <option value=" 200">JONATAN EDUARDO COVARRUBIAS LUNA </option>
              <option value=" 201">JORGE ALBERTO GONZALEZ SANCHEZ</option>
              <option value=" 202">JORGE ALFREDO ARIAS ELIAS </option>
              <option value=" 203">JORGE ANTONIO CANO HUERTA</option>
              <option value=" 204">JORGE ANTONIO PAREDES SILVA</option>
              <option value=" 205">JORGE ARMANDO GARCIA GARCIA</option>
              <option value=" 206">JORGE ARMANDO GOMEZ CISNEROS</option>
              <option value=" 207">JORGE ARTEAGA GUTIERREZ </option>
              <option value=" 208">JORGE ARTURO ARTEAGA GONZALEZ </option>
              <option value=" 209">JORGE BOCANEGRA LOPEZ</option>
              <option value=" 210">JORGE LUIS CORTES CASTELLON</option>
              <option value=" 211">JORGE NAVARRO</option>
              <option value=" 212">JORGE OCTAVIO SANCHEZ SERRANO</option>
              <option value=" 213">JORGE RIOS GUZMAN</option>
              <option value=" 214">JOSE ALBERTO AMEZCUA BALTAZAR</option>
              <option value=" 215">JOSE ALBERTO SANCHEZ ANGUIANO</option>
              <option value=" 216">JOSE ALFREDO GUERRA GONZALEZ</option>
              <option value=" 217">JOSE ANTONIO JIMENEZ NAVA</option>
              <option value=" 218">JOSE ANTONIO VALENCIA CHAVEZ</option>
              <option value=" 219">JOSE ARMANDO PELAYO TERRIQUEZ</option>
              <option value=" 220">JOSE CARLOS OREGEL PELAYO</option>
              <option value=" 221">JOSE CRUZ FLORES ARCINIEGA</option>
              <option value=" 222">JOSE DE JESUS BOCANEGRA LOPEZ</option>
              <option value=" 223">JOSE DE JESUS FLORES SANTANA</option>
              <option value=" 224">JOSE DE JESUS GOMEZ AMAYA</option>
              <option value=" 225">JOSE DOLORES SOTO NAVARRO</option>
              <option value=" 226">JOSE EMMANUEL PUENTE BERNABE</option>
              <option value=" 227">JOSE FLORES SOTO</option>
              <option value=" 228">JOSE FRANCISCO RODRIGUEZ MARDUEÑO</option>
              <option value=" 229">JOSE GUADALUPE ALVARADO ORNELAS</option>
              <option value=" 230">JOSE HOMERO MORALES MARIN </option>
              <option value=" 231">JOSE HUMBERTO AVILAN MARTINEZ </option>
              <option value=" 232">JOSE JESUS GARAY GARCIA</option>
              <option value=" 233">JOSE JOEL ZAMORA SANCHEZ </option>
              <option value=" 234">JOSE LUIS DOMINGUEZ RUIZ</option>
              <option value=" 235">JOSE LUIS GUZMAN SANCHEZ</option>
              <option value=" 236">JOSE LUIS PAZ CARRIZALES</option>
              <option value=" 237">JOSE LUIS QUEZADA HERNANDEZ</option>
              <option value=" 238">JOSE LUIS ZUAREZ MENDOZA</option>
              <option value=" 239">JOSE MANUEL DUEÑAS PONCE</option>
              <option value=" 240">JOSE MANUEL FLORES ZEPEDA</option>
              <option value=" 241">JOSE MANUEL GUADALUPE VELASCO ESPARZA</option>
              <option value=" 242">JOSE MANUEL MERCADO BALLARDO</option>
              <option value=" 243">JOSE MARTINEZ PEREZ</option>
              <option value=" 244">JOSE MENDOZA FLORES</option>
              <option value=" 245">JOSE MIGUEL DE DIOS GONZALEZ</option>
              <option value=" 246">JOSE ORLANDO PELAYO TORRES</option>
              <option value=" 247">JOSE SALVADOR ZAVALZA HERNANDEZ</option>
              <option value=" 248">JOSÉ DE JESUS BARRETO CASTILLO </option>
              <option value=" 249">JUAN ANTONIO ARREOLA HERNANDEZ </option>
              <option value=" 250">JUAN ANTONIO DEL ANGEL</option>
              <option value=" 251">JUAN CARLOS CASTAÑEDA URIBE </option>
              <option value=" 252">JUAN CARLOS HERNANDEZ PARTIDA</option>
              <option value=" 253">JUAN CARLOS NUÑEZ ARECHIGA</option>
              <option value=" 1006">JUAN CARLOS RICO SANTANA</option>

              <option value=" 254">JUAN CARLOS RIVERA PALACIOS</option>
              <option value=" 255">JUAN CARLOS TAPIA CONTRERAS</option>
              <option value=" 256">JUAN DIEGO GIL LOERA</option>
              <option value=" 257">JUAN FLORES MEDINA</option>
              <option value=" 258">JUAN FRANCISCO REYES LOPEZ</option>
              <option value=" 259">JUAN GABRIEL HERNANDEZ RODRIGUEZ</option>
              <option value=" 260">JUAN GABRIEL RIVERA CHAVEZ</option>
              <option value=" 261">JUAN HERNANDEZ RODRIGUEZ</option>
              <option value=" 262">JUAN ISAIAS PEREZ SANCHEZ </option>
              <option value=" 263">JUAN ISRAEL RAMIREZ HERRERA</option>
              <option value=" 264">JUAN JOSE CASTELLON ORTIZ</option>
              <option value=" 265">JUAN MANUEL GABRIEL BALCAZAR</option>
              <option value=" 266">JUAN MANUEL GUTIERREZ PALOMO</option>
              <option value=" 267">JUAN MANUEL SANTANA RAMIREZ</option>
              <option value=" 268">JUAN RAMIRO FLORES VARGAS</option>
              <option value=" 269">JUAN RAMON CRUZ VELAZQUEZ </option>
              <option value=" 270">JUAN RAMON FLORES RODRIGUEZ</option>
              <option value=" 271">JUANA ARIAS LLAMAS</option>
              <option value=" 272">JUDITH SERRATOS CASTAÑEDA</option>
              <option value=" 273">JULIA PINEDA MEJIA</option>
              <option value=" 274">JULIETA ALEJANDRA HERNÁNDEZ CARRILLO </option>
              <option value=" 275">JULIETA DE JESUS ESPINOSA GUERRERO</option>
              <option value=" 276">KARLA NALLELY ZAVALZA URIBE</option>
              <option value=" 277">KARLA PATRICIA LEON GUIJARRO </option>
              <option value=" 278">LAURA ANDREA RIVERA MANCILLA </option>
              <option value=" 279">LAURA ANITA LOPEZ ANACLETO </option>
              <option value=" 280">LAURA ESTHELA MONDRAGON MADRIGAL </option>
              <option value=" 281">LAURA ESTHELA VAZQUEZ MUÑOZ</option>
              <option value=" 282">LAURA MADERA TERRIQUEZ </option>
              <option value=" 283">LAURA SOLORZANO BLAKE</option>
              <option value=" 284">LAURO JIMENEZ CERVANTES</option>
              <option value=" 285">LEOBARDO ANTONIO GONZALEZ CANALES </option>
              <option value=" 286">LEOBARDO DE LA ROSA LEPE</option>
              <option value=" 287">LEONARDO ROMAN ALANIZ RANGEL </option>
              <option value=" 1007">LETICIA DOLORES MORENO MEDINA </option>

              <option value=" 288">LIDIA DEL CARMEN ORTIZ VELASCO</option>
              <option value=" 289">LISETTE LARA GARCIA</option>
              <option value=" 290">LIZBETH GUADALUPE GOMEZ VAZQUEZ</option>
              <option value=" 291">LORENZO CHAGOLLAN GONZALEZ</option>
              <option value=" 292">LUCIA BERONICA CONTRERAS ZAMORA</option>
              <option value=" 293">LUCILA ISABEL PÉREZ MALDONADO </option>
              <option value=" 294">LUIS ALONSO PALOMERA GARCIA </option>
              <option value=" 295">LUIS ARMANDO JOYA ARREOLA</option>
              <option value=" 296">LUIS CARLOS CORONA HERNANDEZ</option>
              <option value=" 297">LUIS EDUARDO MARTIN GRAJEDA JIMENEZ</option>
              <option value=" 298">LUIS EDUARDO ZAVALZA PEREZ </option>
              <option value=" 299">LUIS ENRIQUE GIL LOERA</option>
              <option value=" 300">LUIS ENRIQUE HERNANDEZ RODRIGUEZ</option>
              <option value=" 301">LUIS ERNESTO ESPINOSA</option>
              <option value=" 302">LUIS FELIPE CONTRERAS LOPEZ</option>
              <option value=" 303">LUIS FELIPE FIGUEROA MONDRAGON</option>
              <option value=" 304">LUIS GONZAGA MEDINA MORALES</option>
              <option value=" 1008">LUIS GUILLERMO JIMENEZ RAMOS</option>
              <option value=" 1009">LUIS JORGE VERDEJA VILLASEÑOR</option>

              <option value=" 305">LUIS SAED GUIJARRO</option>
              <option value=" 306">LUISA FERNANDA ANDRADE JOYA</option>
              <option value=" 307">LUZ EMILIA GARCIA ANDRADE</option>
              <option value=" 308">LUZ LARIOS ROSAS</option>
              <option value=" 309">LUZ MARIA IÑIGUEZ RODRIGUEZ</option>
              <option value=" 310">MAGDALENA VELAZQUEZ BARBOSA</option>
              <option value=" 311">MANUEL GONZALEZ VARGAS</option>
              <option value=" 312">MANUEL HUERTA RODRIGUEZ</option>
              <option value=" 313">MANUEL JIMENEZ FLORES</option>
              <option value=" 314">MARCELA IVETH RIOS GARCIA</option>
              <option value=" 315">MARCO ANTONIO MALDONADO VILLA</option>
              <option value=" 316">MARCO ANTONIO SANCHEZ ROJAS</option>
              <option value=" 317">MARCO ANTONIO VARGAS MONTES</option>
              <option value=" 318">MARCO ANTONIO VAZQUEZ LUNA </option>
              <option value=" 319">MARCOS ALBERTO TERRIQUEZ MARTINEZ</option>
              <option value=" 320">MARGARITO ISIDRO MENDOZA</option>
              <option value=" 321">MARGARITO MEDINA LEPE</option>
              <option value=" 322">MARIA DE JESUS GARCIA COVIAN</option>
              <option value=" 323">MARIA DE LOS ANGELES RODRIGUEZ TERRIQUEZ</option>
              <option value=" 324">MARIA DE LOURDES AGUILAR HERNANDEZ</option>
              <option value=" 325">MARIA DEL ROCIO CORTES JIMENEZ</option>
              <option value=" 326">MARIA DEL ROSARIO CASILLAS ARROYO </option>
              <option value=" 327">MARIA DEL SOCORRO GODINEZ RICO</option>
              <option value=" 328">MARIA DEL SOCORRO GUTIERREZ GOMEZ</option>
              <option value=" 329">MARIA DEL SOCORRO IBARRA VAZQUEZ </option>
              <option value=" 330">MARIA DOLORES ORNELAS CORONADO</option>
              <option value=" 331">MARIA EDITH VILLASEÑOR SANCHEZ</option>
              <option value=" 332">MARIA ELENA MICHEL RAMIREZ</option>
              <option value=" 333">MARIA ELIZABETH MEDINA RADILLO </option>
              <option value=" 334">MARIA ELIZABETH ROJO LOPEZ</option>
              <option value=" 335">MARIA EMILIA GOMEZ FLORES</option>
              <option value=" 336">MARIA ESTELA HUERTA CHAVEZ</option>
              <option value=" 1010">MARIA ELENA MICHEL RAMIREZ </option>
              <option value=" 1011">MARIA ELIZABETH ROJO LOPEZ  </option>
              <option value=" 1012">MARIA EMILIA GOMEZ FLORES  </option>
              <option value=" 1013">MARIA ESTHER ROBLES GUERRERO  </option>

              <option value=" 337">MARIA FERNANDA ZARATE RUIZ </option>
              <option value=" 338">MARIA GUADALUPE DIAZ LÓPEZ </option>
              <option value=" 339">MARIA GUADALUPE PALOMAR RODRIGUEZ</option>
              <option value=" 340">MARIA GUADALUPE REYES HERNANDEZ</option>
              <option value=" 341">MARIA GUADALUPE ROJAS CASTAÑON</option>
              <option value=" 1014">MARIA INDIRA BALENZUELA MARTINEZ</option>


              <option value=" 342">MARIA LORENA MARTINEZ CUEVA</option>
              <option value=" 343">MARIA MAGDALENA MEDINA PELAYO</option>
              <option value=" 344">MARIA MATILDE JIMENEZ RUELAS </option>
              <option value=" 345">MARIBEL GOMEZ BUCIO </option>
              <option value=" 346">MARIO LEONARDO DE LA CRUZ RIVERA</option>
              <option value=" 347">MARIO LOPEZ VIZCARRA</option>
              <option value=" 348">MARIO SANCHEZ VENTURA</option>
              <option value=" 349">MARIO VALENZUELA VAZQUEZ</option>
              <option value=" 350">MARIO ZAVALZA HERNANDEZ</option>
              <option value=" 351">MARTHA ALICIA MENDOZA ECHAURI</option>
              <option value=" 352">MARTHA BEATRIZ SARAY MEZA</option>
              <option value=" 353">MARTHA ESTELA CASTAÑEDA CEJA</option>
              <option value=" 354">MARTHA FLORENTINA CORONA SANTANA</option>
              <option value=" 355">MARTHA GRISELDA CHAVEZ AGUILAR</option>
              <option value=" 356">MARTHA LETICIA LUERA ARREOLA</option>
              <option value=" 357">MARTIN AURELIO GRANADOS GRADILLA</option>
              <option value=" 358">MARTIN BERNABE CORONA</option>
              <option value=" 359">MARTIN CASTELLANO LARIOS</option>
              <option value=" 360">MARTIN HERNANDEZ VELOZ</option>
              <option value=" 361">MARTIN JAVIER SOTO AGUILAR</option>
              <option value=" 362">MARÍA TERESA FLORES RUBIO </option>
              <option value=" 363">MAURICIO BIBIAN FLORES</option>
              <option value=" 364">MAURICIO PUDENCIANO DE LUNA PONCE</option>
              <option value=" 365">MAYRA CAROLINA TORRES SANDOVAL</option>
              <option value=" 366">MAYRA GARCIA SALAZAR </option>
              <option value=" 367">MIGUEL ALEJANDRO DIAZ AMBRIZ</option>
              <option value=" 368">MIGUEL ANGEL CASILLAS CHAVARIN</option>
              <option value=" 369">MIGUEL ANGEL DE LA FUENTE VARGAS</option>
              <option value=" 370">MIGUEL ANGEL DE LUNA PONCE </option>
              <option value=" 371">MIGUEL ANGEL FAUSTO</option>
              <option value=" 372">MIGUEL ANGEL RANGEL RENTERIA</option>
              <option value=" 373">MIGUEL ANGEL RODRIGUEZ TORRES</option>
              <option value=" 374">MIGUEL ANGEL SANTANA GONZALEZ</option>
              <option value=" 375">MIGUEL ANGEL TAPIA ALCALA</option>
              <option value=" 376">MIGUEL ANGEL ZEPEDA GONZALEZ</option>
              <option value=" 377">MINERVA GUADALUPE SANTANA CAMPOS</option>
              <option value=" 378">MIRIAM ALEJANDRA MEZA NOGALES </option>
              <option value=" 379">MIRIAM MONTES PEREZ</option>
              <option value=" 380">MOISES FREGOSO GUTIERREZ</option>
              <option value=" 381">MOISES MICHEL GARCIA</option>
              <option value=" 382">MOISES NUÑEZ GUTIERREZ </option>
              <option value=" 383">MONICA GISELA RUEZGAS SEVILLA</option>
              <option value=" 384">MONTSERRAT AGUIRRE RODRIGUEZ </option>
              <option value=" 385">MYRIAM LUJAN ESPINOZA</option>
              <option value=" 386">NABOR LOPEZ RODRIGUEZ</option>
              <option value=" 387">NADXIEL GUADALUPE RAYAS GUTIERREZ</option>
              <option value=" 388">NAHOMY VALENTINA VILLAFAÑA MEJIA </option>
              <option value=" 389">NAIN DE LA CRUZ RIVERA</option>
              <option value=" 390">NANCY ELIZABETH DE LA FUENTE GABRIEL</option>
              <option value=" 391">NESTOR JESUS SANTANA RODRIGUEZ</option>
              <option value=" 392">NESTOR RAMON ROBLES FONSECA</option>
              <option value=" 1015">NOE ANDREY JUAREZ GARCIA</option>

              <option value=" 393">NOEMI MENDEZ GUTIERREZ</option>
              <option value=" 394">NORA LIZETT TEJEDA ARECHIGA</option>
              <option value=" 395">NORMA JANITZIA DIAZ BERNABE </option>
              <option value=" 28">OCTAVIO ALONSO COBIAN NOVOA</option>
              <option value=" 396">OLGA EDITH PELAYO GUTIERREZ</option>
              <option value=" 1016">OLGA EDITH SOSA MUNGUIA</option>

              <option value=" 397">OLIVIO PELAYO PELAYO</option>
              <option value=" 398">OMAR ALEJANDRO ANCIRA VAZQUEZ</option>
              <option value=" 399">OMAR RUIZ LOMELI</option>
              <option value=" 400">OSCAR DANIEL VAZQUEZ RAYAS</option>
              <option value=" 401">OSCAR FLORES PALOMERA</option>
              <option value=" 402">OSCAR MARTIN GARCIA RAMIREZ</option>
              <option value=" 403">OSCAR OMAR MICHEL GALVAN</option>
              <option value=" 404">OSCAR ULISES LONGORIA SOLORZANO</option>
              <option value=" 405">OSCAR VARGAS NUÑEZ</option>
              <option value=" 406">OSVALDO DAVID AMBRIZ MORAN</option>
              <option value=" 407">PAOLA MAYTE BARRETO RIOS </option>
              <option value=" 408">PEDRO DURAN SILVA</option>
              <option value=" 409">PEDRO HERNANDEZ DOROTEO</option>
              <option value=" 410">PEDRO LOPEZ QUINTERO</option>
              <option value=" 411">PEDRO OCTAVIO NORIEGA CASTRO</option>
              <option value=" 412">PEDRO VILLAFAÑA VIDRIO</option>
              <option value=" 413">PERLA KARINA GARCIA PELAYO</option>
              <option value=" 414">PORFIRIO GARCIA COVARRUBIAS</option>
              <option value=" 415">RAFAEL GONZALEZ NUÑEZ</option>
              <option value=" 416">RAFAEL HERNANDEZ TORRES </option>
              <option value=" 417">RAFAEL IVAN ARECHIGA BARBOSA</option>
              <option value=" 418">RAFAEL SANCHEZ ROJAS</option>
              <option value=" 419">RAMIRO ANCIRA ALVARADO</option>
              <option value=" 420">RAMON ANDRADE MANCILLA</option>
              <option value=" 421">RAMON PALOMERA DELGADO</option>
              <option value=" 422">RAMON RODRIGUEZ SALAZAR</option>
              <option value=" 423">RAMON ZAMORA CHAVEZ</option>
              <option value=" 424">RAQUEL CASTILLO QUILES</option>
              <option value=" 425">RAUL ENRIQUEZ CONTRERAS</option>
              <option value=" 426">RAYMUNDO GARCIA OLVERA</option>
              <option value=" 427">RICARDO ARAIZAGA GONZALEZ</option>
              <option value=" 428">RIGOBERTO SANCHEZ SERRANO</option>
              <option value=" 429">RITA HERNANDEZ DE DIOS</option>
              <option value=" 430">ROBERTO ANTONIO OLAGUE GONZALEZ</option>
              <option value=" 431">ROBERTO ATILANO GUTIERREZ GUERRERO </option>
              <option value=" 432">ROBERTO AURELIANO ARTEAGA LUQUIN</option>
              <option value=" 433">ROBERTO MONTES DIAZ</option>
              <option value=" 434">ROCIO ALEJANDRA JIMENEZ HERNÁNDEZ </option>
              <option value=" 435">RODRIGO IÑIGUEZ ROBLES</option>
              <option value=" 436">RODRIGO RADILLO GARCIA</option>
              <option value=" 437">ROGELIO RAMIREZ RAMIREZ </option>
              <option value=" 438">ROSA LIDIA SANCHEZ ORTEGA</option>
              <option value=" 439">ROSALIO IVAN RODRIGUEZ CARDENAS</option>
              <option value=" 440">ROSALIO LOERA ZARATE</option>
              <option value=" 441">RUBEN GARCIA TORRES</option>
              <option value=" 442">RUBEN GONZALEZ ZAMORA</option>
              <option value=" 443">RUBY ISELA NARANJO GARCIA</option>
              <option value=" 444">RUFINO DE JESUS GONZALEZ SANCHEZ</option>
              <option value=" 445">SALMA PULGARIN</option>
              <option value=" 446">SALVADOR HERNANDEZ NOGALES</option>
              <option value=" 447">SALVADOR HUERTA DEL CASTILLO</option>
              <option value=" 448">SALVADOR NAVARRO CORONA</option>
              <option value=" 449">SALVADOR RIOS BELTRAN</option>
              <option value=" 1017">SALVADOR SOTO NAVARRO</option>

              <option value=" 450">SANDRA CECILIA RAMOS MARTINEZ</option>
              <option value=" 451">SANDRA DE NIZ CASTAÑEDA </option>
              <option value=" 452">SANDRA PELAYO CORONA</option>
              <option value=" 453">SANTIAGO GUERRA DELGADO</option>
              <option value=" 1018">SANTOS RODRIGO RADILLO GARCIA</option>

              <option value=" 454">SAUL ISAAC GRANADOS GALLARDO</option>
              <option value=" 455">SAYD JOEL PEREZ ANGELES</option>
              <option value=" 456">SAYURI JOANNA HERNANDEZ ROBLES </option>
              <option value=" 457">SERGIO LIRA PEREZ</option>
              <option value=" 458">SERGIO PONCE LOPEZ</option>
              <option value=" 459">SERGIO RODRIGUEZ MONROY</option>
              <option value=" 460">SIMON MEDINA FREGOSO</option>
              <option value=" 461">SOCHIL MARIETI ROSALES LANDAETA</option>
              <option value=" 462">SOCORRO GÓMEZ RODRÍGUEZ </option>
              <option value=" 463">SOCORRO JANITCIA SANCHEZ LOPEZ</option>
              <option value=" 464">SOCORRO JIMENEZ MENDOZA</option>
              <option value=" 465">TERESA MAGAÑA OROZCO</option>
              <option value=" 466">TOMAS CHAVEZ MARTINEZ</option>
              <option value=" 467">URIEL ALEJANDRO GUZMAN GARCÍA </option>
              <option value=" 468">VERONICA MACIAS VARGAS</option>
              <option value=" 469">VERONICA NOGALES BENAVIDES</option>
              <option value=" 470">VICENTE MANUEL FLORES DE NIZ</option>
              <option value=" 471">VICENTE QUINTERO PLAZOLA</option>
              <option value=" 1019">VICTOR ALEJANDRO AREVALO VELAZQUEZ</option>


              <option value=" 472">VICTOR ALEJANDRO CISNEROS MORALES</option>
              <option value=" 473">VICTOR ALFONSO HERNANDEZ HERNANDEZ</option>
              <option value=" 474">VICTOR HUGO HERNANDEZ NUÑEZ</option>
              <option value=" 475">VICTOR HUGO LOPEZ JIMENEZ</option>
              <option value=" 476">VICTOR HUGO RAYAS URIBE</option>
              <option value=" 477">VICTOR MANUEL CASTRO ZUÑIGA</option>
              <option value=" 478">VICTOR MANUEL GUTIERREZ LEON</option>
              <option value=" 479">VICTOR MANUEL VALDIVIA MARTINEZ</option>
              <option value=" 480">VICTORIO VALENTE DIAZ</option>
              <option value=" 481">VIDAL HERNANDEZ VILLASEÑOR</option>
              <option value=" 482">VIRGINIA GOMEZ MEZA</option>
              <option value=" 483">VIRIDIANA MARISOL CALVARIO ROBLES </option>
              <option value=" 484">WENDOLYNE ALVAREZ ARIAS</option>
              <option value=" 485">WILFRIDO CONTRERAS ALCANTAR</option>
              <option value=" 486">YAHIR ADALBERTO GUZMAN VEGA</option>
              <option value=" 487">YOLANDA MARGARITA PELAYO PELAYO</option>
              <option value=" 488">YOLANDA PLATA GARCIA</option>
              <option value=" 489">ZENAIDA PALOMAR MORALES</option>







            </select>

            <br><label style="margin-top: 2%; margin-right: 1%; font-weight: bold;">Confianza y Contratos:</label>
            <select style="width: 90%;" formControlName="NOMBRE" style="border-radius: 1vh;">

              <option value=" 490">AARON GOMEZ MEZA  </option>
              <option value=" 491">AARON PEDROZA VENTURA  </option>
              <option value=" 492">ABEL GUTIERREZ SANCHEZ  </option>
              <option value=" 1020">ABEL LANDEROS </option>
              <option value=" 493">ABIGAIL CASTRO RUIZ  </option>
              <option value=" 494">ABRAHAM CONTRERAS MARQUEZ</option>
              <option value=" 495">ADELINA BERNAL CRUZ</option>
              <option value=" 496">ADRIANA DEL ROCIO LOPEZ TRUJILLO</option>
              <option value=" 497">AIME VIRIDIANA GUTIERREZ PEREZ</option>
              <option value=" 498">AKIHITO KOSONOY SALAS  </option>
              <option value=" 499">ALBA XIOMARA COVARRUBIAS BELTRAN </option>
              <option value=" 500">ALBERTO AMEZQUITA AYÓN</option>
              <option value=" 1021">ALDO ISAAC FLORES GOMEZ</option>
              <option value=" 1022">ALDO RAFAEL  PELAYO GARCIA </option>
              <option value=" 501">ÁLVARO PELAYO GOMEZ</option>
              <option value=" 502">ALEJANDRA GUADALUPE CASTILLO BRAVO </option>
              <option value=" 503">ALEJANDRA MICHELLE VAZQUEZ GARCIA</option>
              <option value=" 504">ALEJANDRO GARCIA BARBOSA</option>
              <option value=" 505">ALEJANDRO PEREZ COVARRUBIAS</option>
              <option value=" 506">ALEXANDER FLORES ARIAS</option>
              <option value=" 507">ALEXIS DANIEL AMARAL RANGEL</option>
              <option value=" 508">ALEXIS EDUARDO OCHO QUINTERO</option>
              <option value=" 509">ALEXIS PAUL CORONA GALLARDO </option>
              <option value=" 510">ALFREDO ALFONSO GOMEZ RODRÍGUEZ</option>
              <option value=" 511">ALFREDO DE JESUS NAVARRO GUZMAN</option>
              <option value=" 512">AMADOR REYES EMILIANO </option>
              <option value=" 513">ANA GABRIELA PAZ MANCILLA </option>
              <option value=" 514">ANA KAREN CORONA PEREZ</option>
              <option value=" 515">ANA PAOLA GRAJEDA MONTES </option>
              <option value=" 516">ANA ROSA  GOMEZ RAMIREZ </option>
              <option value=" 517">ANA ROSA PANDURO AGUILAR</option>
              <option value=" 518">ANAHI PAMELA GARCIA VERGARA</option>
              <option value=" 519">ANDREA ESTEPHANIA REYNOSO GUTIERREZ </option>
              <option value=" 520">ANDREA PAOLA  GOMEZ ESPINOZA </option>
              <option value=" 521">ANGEL ALEXIS RUIZ GOMEZ</option>
              <option value=" 522">ANGEL GABRIEL PALACIOS HERNANDEZ</option>
              <option value=" 523">ANGEL GIOVANNI ANDRADE PEREZ</option>
              <option value=" 524">ANGEL HIGINIO CONTRERAS CONTRERAS </option>
              <option value=" 525">ANGEL MOISES  GODINEZ RODRIGUEZ </option>
              <option value=" 526">ANGELICA ELIZABETH  GOMEZ AGUILAR </option>
              <option value=" 527">ANGELICA PAOLA DURAN AVALOS</option>
              <option value=" 528">ANTONIO  LEON GARCIA </option>
              <option value=" 529">ANTONIO DE JESUS MEDINA GARCIA</option>
              <option value=" 530">ARLET REGINA OBREGÓN OLAGUE</option>
              <option value=" 531">ARMANDO PEDRAZA ROSAS</option>
              <option value=" 532">ARREOLA LOPEZ HAIDYD</option>
              <option value=" 533">ARTURO ELEUTERIO  VERA RODRIGUEZ   </option>
              <option value=" 534">ARTURO EMMANUEL RUVALCABA FLORES</option>


              <option value=" 535">BERTHA ALICIA IÑIGUEZ BRAMBILA</option>
              <option value=" 536">BETSAIDA BELEN CHAGOLLAN RANGEL</option>
              <option value=" 537"> BILLIE DURYENKA SOSA PERALTA</option>
              <option value=" 538"> BLANCA AMPARO DE NIZ VILLA</option>
              <option value=" 539"> BRANDON IVAN GONZALEZ MOYA</option>
              <option value=" 540"> BRENDA JAZMIN GONZALEZ ESTRADA</option>
              <option value=" 541">BRYAN AZAEL RODRIGUEZ TOSCANO</option>


              <option value=" 542">CANDE NOEMY SAGRERO CASTAÑEDA </option>
              <option value=" 543">CARLO AMADOR IÑIGUEZ DÍAZ</option>
              <option value=" 544">CARLOS ALBERTO CONTRERAS VARGAS </option>
              <option value=" 545">CARLOS ALFONSO ALVARADO NAVARRO</option>
              <option value=" 546">CARLOS FROYLAN RUVALCABA FLORES</option>
              <option value=" 547">CARLOS IGNACIO CASTELLON LOPEZ </option>
              <option value=" 548">CARLOS MANUEL FLORES JIMENEZ </option>
              <option value=" 549">CARLOS MISSAEL BUGARIN MICHEL</option>
              <option value=" 550">CARLOS OMAR GARCIA REYNA</option>
              <option value=" 551">CERVANTES FLORES ROSA</option>
              <option value=" 552">CESAR HERNANDEZ PALACIOS </option>
              <option value=" 553">CESAR ALEJANDRO  NAVARRO LLAMAS </option>
              <option value=" 554">CESAR DANIEL MEDINA CASILLAS </option>
              <option value=" 555">CESAR DANIEL IGLESIAS HERNANDEZ</option>
              <option value=" 556">CESAR EDUARDO FRANCO AVALOS</option>
              <option value=" 557">CESAR OMAR COPADO FIGUEROA</option>
              <option value=" 558">CHRISTIAN FAUSTINO MARTINEZ MARTINEZ</option>
              <option value=" 559">CHRISTIAN MARTINEZ MARTINEZ</option>
              <option value=" 560">CHRISTIAN RAMÓN CANALES SOLTERO</option>
              <option value=" 561">CINTHIA JANET GOMEZ JIMENEZ</option>
              <option value=" 562">CLAUDIA CRISTINA MURGUIA RAMIREZ</option>
              <option value=" 563">CLAUDIA ELEJANDRA GALVAN GARCIA </option>
              <option value=" 564">CLAUDIA ELIZABETH GARCIA GONZALEZ</option>
              <option value=" 565">CLAUDIA ESPERANZA ESPINOSA ZARATE</option>
              <option value=" 566">CRESCENCIO BENIGNO PELAYO GABRIEL </option>
              <option value=" 567"> CRISTIAN OSIEL RODRIGUEZ SOLORZANO </option>
              <option value=" 568"> CRISTINA  FLORES SOTO </option>
              <option value=" 569"> CRISTINO SANCHEZ OROZCO  </option>



              <option value=" 570">DAEN RAMON  GALVAN MATA </option>
              <option value=" 571"> DAGOBERTO  TRUJILLO HERNANDEZ </option>
              <option value=" 572">DALIA AZUCENA PELAYO NARANJO</option>
              <option value=" 573"> DALIA ELIZABETH  PELAYO GALINDO </option>
              <option value=" 574"> DANIEL  NUÑEZ ESTRADA </option>
              <option value=" 575">DANIEL GUTIERREZ HERNANDEZ </option>
              <option value=" 576"> DANIEL ISBAN HERNANDEZ VELOZ</option>
              <option value=" 577">DANIELA SARAHÍ SANCHEZ SERRANO</option>
              <option value=" 578">DAVID HUERTA SOTO</option>
              <option value=" 579">DAYL ILIANA SABAH GALEANA</option>
              <option value=" 580"> DEISY ANAHI LLAMAS LOPEZ</option>
              <option value=" 581">DENIS DEL SOCORRO  MICHEL DECENA </option>
              <option value=" 582">DIANA GISSELA  LOPEZ BORBON </option>
              <option value=" 583">  DIEGO ARMANDO GARCIA GARCIA</option>
              <option value=" 854"> DIEGO ARMANDO HERNANDEZ ARREOLA</option>
              <option value=" 585">DIEGO JOSE ALVAREZ MEZA</option>
              <option value=" 586">DIGNA PATRICIA LIMON MICHEL </option>
              <option value=" 587">DULCE ROSARIO BAÑALES MONROY</option>


              <option value=" 588">EDGAR ALEJANDRO CERNA ORNELAS </option>
              <option value=" 589">EDGAR ALEXANDER FLORES SENTENO </option>
              <option value=" 590">EDGAR EFRAIN ESTRADA HERNANDEZ </option>
              <option value=" 591">EDGAR TONATIUH  MONTAÑO GALVAN </option>
              <option value=" 592">EDUARDO CORONA VILLANUEVA</option>
              <option value=" 593">EDUARDO GUADALUPE PEREZ PEREZ</option>
              <option value=" 594">EDUARDO LARIOS MARTINEZ</option>
              <option value=" 595">EDUARDO SALVADOR MACIAS LUNA</option>
              <option value=" 596">EFREN  MONTAÑO MEDINA</option>
              <option value=" 597">ELIAS  GONZALEZ NUÑEZ </option>
              <option value=" 598">ELVIA CARRILLO LOPEZ</option>
              <option value=" 599"> EMILIANO AMADOR REYES</option>
              <option value=" 600">EMMANUEL GONZALEZ GARCIA</option>
              <option value=" 601"> ERIC EDUARDO VILLANUEVA ALVAREZ</option>
              <option value=" 602">ERIC PELAYO SUAZO</option>
              <option value=" 603">ERICK ALEJANDRO ALANIZ GARCIA</option>
              <option value=" 604">ERIKA ANGELINA HERNANDEZ HERNANDEZ</option>
              <option value=" 605">ERIKA MARIEL BARBOSA ANCIRA</option>
              <option value=" 606"> ERNESTO ALONSO RODRIGUEZ HERNANDEZ</option>
              <option value=" 607">ESDRAS LOPEZ MUNDO </option>
              <option value=" 608">ESTHELA JUDITH CORONA MARTINEZ</option>
              <option value=" 609"> ESTHER ALEJANDRA ARREOLA PELAYO</option>
              <option value=" 610">EUGENIO VILLALVAZO GARCIA DE ALBA</option>
              <option value=" 611">EUSTORGIO BALENSUELA MARTINEZ </option>
              <option value=" 612">EVELYN EDITH RAMOS RIOS</option>
              <option value=" 613">EVELYN ITZEL PEREZ RAMOS </option>




              <option value=" 615">FABIAN ZAMORA CHICAS</option>
              <option value=" 616">FATIMA ESMERALDA RODRIGUEZ ORTEGA</option>
              <option value=" 617">FATIMA XIOMARA MARTINEZ CANAL</option>
              <option value=" 618">FAUSTO MANCILLA MARTINEZ  </option>
              <option value=" 619">FELICIANO RAMIREZ MARIZ  </option>
              <option value=" 620">FELIPE ALBERTO RAMIREZ PEREZ </option>
              <option value=" 621"> FELIX OCTAVIANO ACEVES GUERRERO </option>
              <option value=" 622">FERNANDO GOMEZ YEO</option>
              <option value=" 623"> FERNANDO MOCTEZUMA MAGAÑA ROBLES</option>
              <option value=" 624"> FRANCISCO  LIMON GASTELUM </option>
              <option value=" 625">FRANCISCO CARDENAS ESTRELLA </option>
              <option value=" 626"> FRANCISCO ELIAS CASTILLO OROZCO </option>
              <option value=" 627">FRANCISCO EMMANUEL GARCIA BARRETO</option>
              <option value=" 628">FRANCISCO GARCIA RAMIREZ</option>
              <option value=" 629">FRANCISCO ISRAEL REYNA VAZQUEZ</option>
              <option value=" 630">FRANCISCO JAFET  FLORES BARRETO </option>
              <option value=" 631">FRANCISCO JAVIER  GUERRERO BELTRAN </option>
              <option value=" 632">FRANCISCO JAVIER  MENDOZA NAVA </option>
              <option value=" 633">FRANCISCO JAVIER GOMEZ CANAL</option>
              <option value=" 634">FRANCISCO JAVIER HORTA MONTAÑO</option>
              <option value=" 635">FRANCISCO JAVIER PALOMAR AMEZQUITA</option>
              <option value=" 636"> FRANCISCO JOAQUIN  MADERA LEON </option>
              <option value=" 637">FRANCISCO JOQUIN PELAYO GUZMAN</option>
              <option value=" 638">FRAY PEDRO  GOMEZ RODRIGUEZ</option>


              <option value=" 639">GABRIEL  SANTILLAN VALLEJO </option>
              <option value=" 640">GABRIEL TOSCANO ROSALES</option>
              <option value=" 641">GABRIELA DIAZ NUÑEZ </option>
              <option value=" 642">GABRIELA MARIA RODRIGUEZ VAZQUEZ</option>
              <option value=" 643">GAMA SORIA VEIRUTH</option>
              <option value=" 644">GARAY PUENTE JUAN LUIS</option>
              <option value=" 645">GENOVEVA SAMARA CRUZ SOLORZANO </option>
              <option value=" 646">GENOVEVA SARAHI  MENDOZA NAVA</option>
              <option value=" 647">GEOVANNY BETANCOURT ORTEGA </option>
              <option value=" 648">GERMAN ALVAREZ COVARRUBIAS</option>
              <option value=" 649">GIBRAN RAMON  GOMEZ ZARAGOZA </option>
              <option value=" 650">GILDARY STEPHANIE BALLARDO BRAMBILA</option>
              <option value=" 651">GODINEZ ARTURO DE LA TORRE </option>
              <option value=" 652">GOMEZ CARREÑO VICTOR MANUEL</option>
              <option value=" 653">GOMEZ TERRIQUEZ SILVIA ESMERALDA</option>
              <option value=" 654">GONZALEZ TAPIA LAURA ESTELA</option>
              <option value=" 655">GORETIS LOZANO PEREZ</option>
              <option value=" 656">GRECIA GUDALUPE GUERRERO GASPAR</option>
              <option value=" 657">GRECIA MICHEL MADERA</option>
              <option value=" 658"> GUADALUPE LOPEZ AGUILAR</option>
              <option value=" 659">GUADALUPE NAHOMY BALCAZAR TOSCANO</option>
              <option value=" 660">GUILERMO ALEJANDRO IÑIGUEZ DIAZ</option>
              <option value=" 661"> GUILLERMO ESPINOZA SOLORZANO</option>
              <option value=" 662">GUSTAVO  VARGAS NUÑEZ</option>
              <option value=" 663"> GUSTAVO SALVADOR ROBLES MARTINEZ</option>
              <option value=" 664"> GÉNESIS EDITH PEREZ VILLASEÑOR</option>



              <option value=" 665">HAIDYD ARREOLA LOPEZ </option>
              <option value=" 666">HECTOR ADOLFO NUÑEZ ZARATE </option>
              <option value=" 667">HECTOR AGUSTIN AVALOS RODRIGUEZ</option>
              <option value=" 668">HECTOR ALEJANDRO CASILLAS BRAMBILA</option>
              <option value=" 669">HECTOR AMBROCIO  PELAYO GUZMAN</option>
              <option value=" 670">HECTOR GUADALUPE IÑIGUEZ GARCIA</option>
              <option value=" 671">HECTOR IVAN MENDOZA MORAN</option>
              <option value=" 672">HECTOR MANUEL COVARRUBIAS ACOSTA</option>
              <option value=" 673">HECTOR MANUEL PELAYO BARRAGAN</option>
              <option value=" 674">HECTOR MIGUEL CERVANTES CURIEL</option>
              <option value=" 675">HECTOR MOISES FLORES PEREZ</option>
              <option value=" 676">HILARIO CAYETANO DOMINGUEZ</option>


              <option value=" 677">ILIANA ESTEFANIA PONCE GABRIEL</option>
              <option value=" 678">IMELDA GOMEZ MACEDO</option>
              <option value=" 679">IRAN LARA AMADOR</option>
              <option value=" 680">IRAN ZUSAN  AVILAN MARTINEZ </option>
              <option value=" 681">IRMA CORONADO RODRIGUEZ</option>
              <option value=" 682">IRMA LORENA  JIMENEZ ALBA </option>
              <option value=" 683">ISAAC ENRIQUE HERNANDEZ GARCIA</option>
              <option value=" 684">ISAAC JAAZIEL ANCIRA PADILLA</option>
              <option value=" 685">ISAAC OSWALDO LOPEZ ZUÑIGA</option>
              <option value=" 686">ISAI EMMANUEL RIVERA</option>
              <option value=" 687">ISIS YOSELIN  GONZALEZ GUERRA </option>
              <option value=" 688">ITZAYANI  MUÑOZ SANCHEZ </option>
              <option value=" 689">IVAN ANTONIO FLORES PAMPLONA</option>
              <option value=" 690">IVAN LEONARDO ANGUIANO CASTRO</option>


              <option value=" 700">J. JESUS  PEREZ MACIAS</option>
              <option value=" 701">J. REFUGIO LUNA ROMERO</option>
              <option value=" 702">J NICOLAS AYALA DEL REAL</option>
              <option value=" 703">J REFUGIO LUNA ROMERO </option>
              <option value=" 704">JACOB MEDINA CERVANTES</option>
              <option value=" 705">JAIME ANTONIO HERNENDEZ HERNANDEZ</option>
              <option value=" 706">JAIME DESIDERO RUELAS RUBIO</option>
              <option value=" 707"> JAIME RENE  MONTAÑO GARCIA </option>
              <option value=" 708"> JAIME RODRIGUEZ RODRIGUEZ</option>
              <option value=" 709">JANETH MARICRUZ VILLANUEVA SOLTERO</option>
              <option value=" 710">JAVIER ALEJANDRO RAMIREZ VIZCARRA</option>
              <option value=" 711">JAVIER ERNESTO GARCIA FLORES</option>
              <option value=" 712"> JAZMIN CARELY TERRIQUEZ SÁNCHEZ</option>
              <option value=" 713">JENNIFER QUINTERIO VILLASEÑOR</option>
              <option value=" 714">JESSICA SILVANA SERRANO RAMIREZ</option>
              <option value=" 715"> JESUS ALEJANDRO  MARMOLEJO LOPEZ </option>
              <option value=" 716"> JESUS ALEJANDRO CASTAÑEDA SERRANO</option>
              <option value=" 717"> JESUS GUADALUPE LONGORIA SOLORZANO</option>
              <option value=" 718">JESUS ROBLES GONZALEZ</option>
              <option value=" 719">JESUS SALVADOR AGUILAR VELASCO</option>
              <option value=" 720"> JOANNA GABRIELA  PRECIADO RUELAS </option>
              <option value=" 721"> JOAQUIN OSIEL  GUTIERREZ SOLORZANO </option>
              <option value=" 722">JOEL ADLAY  HERNANDEZ CISNEROS </option>
              <option value=" 723">JOEL RAFAEL SANCHEZ GOMEZ</option>
              <option value=" 724">JONATHAN  MADRIGAL SOTO</option>
              <option value=" 725"> JONATHAN ANDRES  FIGUEROA RODRIGUEZ </option>
              <option value=" 726">JONATHAN DANIEL MURGUIA TERRIQUEZ</option>
              <option value=" 727"> JORGE  GONZALEZ NUÑEZ</option>
              <option value=" 728">JORGE ALFONSO  FRANCO CHAVEZ </option>
              <option value=" 729">JORGE AMADOR GONZALEZ PELAYO</option>
              <option value=" 730"> JORGE ANDRES REYES RODRIGUEZ</option>
              <option value=" 731">JORGE ANTONIO MARTINEZ HERNANDEZ </option>
              <option value=" 732"> JORGE ARTURO ARTEAGA GONZALEZ </option>
              <option value=" 733">JORGE DANIEL GARCIA BARRAGÁN</option>
              <option value=" 734">JORGE LUIS ESCOBEDO CASILLAS</option>
              <option value=" 735">JOSE  VALDOVINOS LOPEZ </option>
              <option value=" 736"> JOSE ALBERTO CORONA CRUZ</option>
              <option value=" 737">JOSE ANGEL CERNAS MOLINA</option>
              <option value=" 738">JOSE ANGEL HERNÁNDEZ GARCIA</option>
              <option value=" 739">JOSE ANTONIO CORONA MARTINEZ</option>
              <option value=" 740">JOSE ASUNCIÓN GUTIERREZ VAZQUEZ</option>
              <option value=" 741">JOSE DE JESUS  DIAZ GARCIA</option>
              <option value=" 742"> JOSE DE JESUS BARRETO</option>
              <option value=" 743">JOSE DE JESUS GUZMAN IÑIGUEZ</option>
              <option value=" 744">JOSE DE JESUS HERNÁNDEZ BRAMBILA</option>
              <option value=" 745">JOSE DE JESUS IÑIGUEZ GUZMAN</option>
              <option value=" 746">JOSE EDUARDO  GALLEGOS BALENZUELA</option>
              <option value=" 747">JOSE EDUARDO CAZARES HERNANDEZ</option>
              <option value=" 748">JOSE EDUARDO GARAY PUENTE</option>
              <option value=" 749">JOSE EMMANUEL  VELAZQUEZ RIOS</option>
              <option value=" 750">JOSE EMMANUEL PUENTE BERNABE </option>
              <option value=" 751">JOSE ENRIQUE LOPEZ PADILLA</option>
              <option value=" 752">JOSE GUADALUPE ESCALANTE RODRIGUEZ</option>
              <option value=" 753">JOSE HOMERO  MORALES MARIN</option>
              <option value=" 754">JOSE HUGO MURO ARIAS</option>
              <option value=" 755">JOSE JACINTO  LOERA PELAYO</option>
              <option value=" 756">JOSE MANUEL  TORRES TORRES </option>
              <option value=" 757">JOSE MANUEL SANTANA CASILLAS </option>
              <option value=" 758">JOSE MANUEL SOTO AGUILAR</option>
              <option value=" 759">JOSE MARTINO GONZALEZ QUINTERO</option>
              <option value=" 760">JOSE PAULO MARTINEZ GABRIEL</option>
              <option value=" 761">JOSE PEDRO  GARCIA CURIEL </option>
              <option value=" 762">JOSE RUBEN CASTILLO ESTRADA</option>
              <option value=" 763">JOSUE ASAF AYON SOTO</option>
              <option value=" 764"> JUAN CARLOS   NIEVES LOPEZ </option>
              <option value=" 765">JUAN CARLOS AGUILAR CHAVEZ</option>
              <option value=" 766">JUAN CARLOS CARRILLO FAUSTO</option>
              <option value=" 767">JUAN CARLOS CASTAÑEDA URIBE</option>
              <option value=" 768">JUAN CARLOS FREGOSO SANCHEZ</option>
              <option value=" 769">JUAN CARLOS MLDONADO VILLAFAÑA</option>
              <option value=" 770">JUAN FABIAN RAMSES  GOMEZ BARRAGAN</option>
              <option value=" 771">JUAN IGNACIO ARROYO VERASTEGUI </option>
              <option value=" 772">JUAN ISAIAS  PEREZ SANCHEZ </option>
              <option value=" 773">JUAN JOSE COVARRUBIAS ACOSTA</option>
              <option value=" 774">JUAN MIGUEL RAMOS ESQUIVEL</option>
              <option value=" 775">JUAN PABLO BRAMBILA CONTRERAS</option>
              <option value=" 776">JUAN RAFAEL AMBRIZ CAMACHO</option>
              <option value=" 777">JUAN RAMÓN BELTRAN MUÑOZ</option>
              <option value=" 778">JUAN RIVERA ALVAREZ </option>
              <option value=" 779">JULIETA ALEJANDRA HERNANDEZ CARRILLO</option>
              <option value=" 780">JULIETA BETZABE CASTRO GARCIA</option>
              <option value=" 781">JULIO CESAR GOMEZ BARBOSA</option>


              <option value=" 784">KATHIA YASLIN DIAZ JIMENEZ </option>
              <option value=" 785">KAREN CRISTINA MORALES MENDOZA </option>
              <option value=" 786">KARLA LILIANA  GONZALEZ COVARRUBIAS </option>
              <option value=" 787">KENIA PAOLA GARCIA VILLAFAÑA</option>
              <option value=" 788">KEVIN EDRAI CEBALLOS GOMEZ</option>
              <option value=" 789">KEVIN GERARDO BARBARIN ZAMORA</option>
              <option value=" 790">KEVIN ISAAC VIZCARRA PEÑA</option>


              <option value=" 791">LAURA  MADERA TERRIQUEZ</option>
              <option value=" 792">LAURA ISABEL  GOMEZ MARTINEZ</option>
              <option value=" 793">LAURA SUSANA CONTRERAS BELTRAN</option>
              <option value=" 794">LEONARDO PELAYO LOPEZ</option>
              <option value=" 795">LIZETH ALONDRA ZARAGOZA MARTINEZ</option>
              <option value=" 796">LORENZO JUAREZ ORTIZ</option>
              <option value=" 797">LUCILA ISABEL  PEREZ MALDONADO </option>
              <option value=" 798">LUCIO EVERARDO   HERNANDEZ REYES</option>
              <option value=" 799">LUIS ADALBERTO AMBRIZ MORAN</option>
              <option value=" 800">LUIS ALBERTO  HARO MARTINEZ </option>
              <option value=" 801">LUIS ALONSO  DE LOERA GARCIA</option>
              <option value=" 802">LUIS ALONSO RANGEL GARCIA </option>
              <option value=" 803">LUIS ARMANDO PEREZ</option>
              <option value=" 804">LUIS DAVID RAMIREZ ROSALES</option>
              <option value=" 805">LUIS ERNESTO PEREZ PEREZ</option>
              <option value=" 806">LUIS FROYLAN MANCILLA PANDURO</option>
              <option value=" 807">LUIS IVAN CHAVEZ DELGADILLO</option>
              <option value=" 808">LUIS JORGE   VERDEJA VILLASEÑOR</option>
              <option value=" 809">LUIS MANUEL NUÑEZ ESTRADA</option>
              <option value=" 810">LUIS RAFAEL NOVOA PEÑA</option>
              <option value=" 811"> LUISA FERNANDA ANDRADE JOYA</option>
              <option value=" 812"> LUZ DEL ROSARIO MARTINEZ LARIOS</option>



              <option value=" 813">MA. DE JESUS ESTRADA BATISTA</option>
              <option value=" 814"> MA. DE LOURDES  GARCIA LOPEZ</option>
              <option value=" 815">MA. ELENA   OLIVA BELTRAN </option>
              <option value=" 816">MA. GUADALUPE ALONSO ROJAS</option>
              <option value=" 817">MA.GUADALUPE CARRANZA BARRAGAN</option>
              <option value=" 818"> MAELVY GRACIELA  GUERRERO BLANCAS</option>
              <option value=" 819">MANUEL ALEJANDRO  GARCIA ARAIZA </option>
              <option value=" 820"> MANUEL GOMEZ CISNEROS </option>
              <option value=" 821">MARCO ANTONIO  VAZQUEZ LUNA</option>
              <option value=" 822">MARCO ANTONIO CAMACHO MALENO</option>
              <option value=" 823"> MARCO VINICIO RODRIGUEZ QUINTERO </option>
              <option value=" 824"> MARDUEÑO IBARRA MIGUEL</option>
              <option value=" 825"> MARIA ARACELI FLORES MONTES</option>
              <option value=" 826">MARIA ARACELI FLORES MONTES</option>
              <option value=" 827"> MARIA BRAYAN AMADOR </option>
              <option value=" 828"> MARIA CARMEN VALENZUELA BOIZO</option>
              <option value=" 829">MARIA CRISTINA  MARTINEZ GARCIA</option>
              <option value=" 830">MARIA DEL CARMEN   MATA MADRIGAL </option>
              <option value=" 831">MARIA DEL ROSARIO  SEVILLA CASILLAS </option>
              <option value=" 832">MARIA DEL ROSARIO CASILLAS ARROYO</option>
              <option value=" 833">MARIA DEL ROSARIO CASTREJON VIDRIO </option>
              <option value=" 834">MARIA DEL SOCORRO RUELAS RUBIO </option>
              <option value=" 835"> MARIA ELENA HUERTA CHAVEZ </option>
              <option value=" 836">MARIA ELIZABETH  MEDINA RADILLO </option>
              <option value=" 837">MARIA ELVIRA MONTES GÓMEZ</option>
              <option value=" 838"> MARIA GUADALUPE  DIAZ LOPEZ </option>
              <option value=" 839">MARIA GUADALUPE  MEDINA MARAVILLA </option>
              <option value=" 840">MARIA GUADALUPE ANDRADE </option>
              <option value=" 841">MARIA ISABEL ZAMORA PADILLA</option>
              <option value=" 842">MARIA JANETH   MORAN FLORES </option>
              <option value=" 843">MARIA MATILDE  JIMENEZ RUELAS </option>
              <option value=" 844">MARIANA ROMERO PRECIADO </option>
              <option value=" 845"> MARIBEL GOMEZ BUCIO </option>
              <option value=" 846"> MARIO ALBERTO VARGAS CALVARIO</option>
              <option value=" 847"> MARIO NUÑEZ</option>
              <option value=" 848">MARITZA ELIZABETH VICTORINO GUZMAN</option>
              <option value=" 849">MARITZA JAZMIN BARAJAS FIGUEROA</option>
              <option value=" 850">MARTHA CELINA  TORRES RUIZ</option>
              <option value=" 851">MARTHA FABIOLA DURAN CASTILLO</option>
              <option value=" 852">MARTHA GRISELDA CHAVEZ AGUILAR</option>
              <option value=" 853">MARTHA JUDITH JIMENEZ AMAYA</option>
              <option value=" 854"> MARTHA LETICIA  PEREZ HERNANDEZ</option>
              <option value=" 855">MARTIN  AVALOS VERDE</option>
              <option value=" 856">MARTIN EZEQUIEL RADILLO GALLARDO</option>
              <option value=" 857">MAURICIO MAGAÑA GARCIA</option>
              <option value=" 858">MAYRA GARCIA SALAZAR</option>
              <option value=" 859">MELISA VARGAS BARRETO</option>
              <option value=" 860">MENDEZ PARRA WALTER ALEJANDRO</option>
              <option value=" 861">MIGUEL ALONSO  MORAN JIMENEZ </option>
              <option value=" 862">MIGUEL ANGEL  DE LUNA PONCE </option>
              <option value=" 863">MIGUEL ANGEL CASTILLO RODRIGUEZ</option>
              <option value=" 864">MIGUEL ANGEL IÑIGUEZ BRAMBILA</option>
              <option value=" 865">MIGUEL ANGEL SANTANA GONZALEZ</option>
              <option value=" 866">MIGUEL ANGELDE LA CRUZ LOPEZ</option>
              <option value=" 867">MIGUEL GONZALEZ FONSECA</option>
              <option value=" 868">MIRIAM DIAZ BENITEZ</option>
              <option value=" 869">MIRIAN CRISTINA  MONTES HERNANDEZ</option>
              <option value=" 870">MITCHEL GARCIA ESPARZA </option>
              <option value=" 871">MOISES MUÑOZ MICHEL</option>
              <option value=" 872">MOISES PÉLAYO GUERRA</option>
              <option value=" 873">MONICA PRISCILA  RAMIREZ QUINTERO</option>
              <option value=" 874">MONTSERRAT GUITRON NEGRETE</option>
              <option value=" 875">MYRIAM LUJAN ESPINOZA</option>
              <option value=" 876">MÓNICA JAZMIN JIMÉNEZ MARTÍNEZ</option>
              <option value=" 877">NANCY LILIANA ALVAREZ SOLORZANO</option>
              <option value=" 878">NATASSA FRANCESCA  SALAS BARTILOTTI</option>
              <option value=" 879">NAVARRO MEDINA SERVANDO</option>
              <option value=" 880">NELLY YALITZA LOPEZ MARDUEÑO</option>
              <option value=" 881">NOE BALENSUELA MARTINEZ</option>
              <option value=" 882">NOE PEREZ AGUILAR </option>
              <option value=" 883">NORA ANGELICA VELASCO MARTINEZ</option>
              <option value=" 884">NORMA JANITZIA  DIAZ BERNABE </option>
              <option value=" 885">NORMA NORINDA RUIZ VILLALVAZO</option>

              <option value=" 886">OBDULIA MATA GUZMAN</option>
              <option value=" 887">OCTAVIO ALONSO COBIAN NOVOA</option>
              <option value=" 888">OCTAVIO DAVID GUTIERREZ LUQUIN</option>
              <option value=" 889">OLEGARIO LARA ARELLANO</option>
              <option value=" 890">ORALIA GONZALEZ TORRES</option>
              <option value=" 891">ORLANDO ISRAEL PADILLA LOERA</option>
              <option value=" 892">ORLANDO ISRAEL RAMIREZ RAMIREZ</option>
              <option value=" 893">OSCAR  GUZMAN VARGAS</option>
              <option value=" 894">OSCAR DANIEL  VAZQUEZ RAYAS </option>
              <option value=" 895">OSCAR GUADALUPE PRECIADO PADILLA</option>
              <option value=" 896">OSCAR JOSUE GOMEZ RÍOS</option>
              <option value=" 897">OSCAR OMAR  MERCADO PEREZ </option>
              <option value=" 898"> OSCAR OMAR RODRIGUEZ BALCAZAR</option>
              <option value=" 899">OSCAR RODRÍGUEZ HERNÁNDEZ </option>
              <option value=" 900">OSWALDO GUTIERREZ MAYORQUIN</option>
              <option value=" 901"> PAOLA DANIELA  HERNANDEZ URIBE </option>
              <option value=" 902"> PATRICIA  MENDEZ SALAZAR </option>
              <option value=" 903"> PAULINO PADILLA MONZON</option>
              <option value=" 904">PEDRO ALEJANDRO PAEZ LLAMAS</option>
              <option value=" 905"> PEDRO GERMAN RODRIGUEZ LOPEZ </option>
              <option value=" 906"> PEDRO MICHEL ZERMEÑO</option>
              <option value=" 907"> QUIRINA  URZUA BARRERA </option>

              <option value=" 908">RAFAEL ALEJANDRO   MONTES ZAMORA </option>
              <option value=" 909">RAFAEL HERNANDEZ RICO</option>
              <option value=" 1030">RAFAEL HERNANDEZ TORRES</option>
              <option value=" 910">RAMON GONZALEZ SANCHEZ</option>
              <option value=" 911">RAUL DE JESUS LLAMAS LOYOLA</option>
              <option value=" 912">RAYMUNDO LANDEROS BURBANO</option>
              <option value=" 913">RICARDO DE LA PEÑA DE LA ROSA</option>
              <option value=" 914">RICARDO EMMANUEL REYES CANO </option>
              <option value=" 915">RIGOBERTO AGUILAR</option>
              <option value=" 916">RIGOBERTO MONTES VIDRIO </option>
              <option value=" 917">ROBERTO ATILANO GUTIERREZ GUERRERO</option>
              <option value=" 918">ROBERTO RANGEL COBIAN</option>
              <option value=" 919">ROCIO ALEJANDRA  JIMENEZ HERNANDEZ </option>
              <option value=" 920">ROGELIO CASILLAS TORRES</option>
              <option value=" 921">ROSA ILDA  TORO AMBROSIO </option>
              <option value=" 922"> ROSA ISELA PLACITO SANCHEZ</option>
              <option value=" 923">ROSA MARLENNY RODRIGUEZ PELAYO </option>
              <option value=" 924">ROSA MAYRA BETERAN SANTANA </option>

              <option value=" 925">SABINO LARA GUTIERREZ</option>
              <option value=" 926">SANDRA ANAYELI FLORES ALVAREZ</option>
              <option value=" 927">SANDRA JANITZIA  DEL TORO DUEÑAS </option>
              <option value=" 928">SANTIAGO GAVIÑO BALDOVINOS </option>
              <option value=" 929">SARA MIREYA MENDOZA PELAYO</option>
              <option value=" 930">SARAY MEZA HORACIO  </option>
              <option value=" 931">SAUL GABRIEL MEJIA</option>
              <option value=" 932">SAUL GONZALEZ LOPEZ</option>
              <option value=" 933">SAUL RICARDO NIETO DÍAZ</option>
              <option value=" 934">SERGIO AMIN CASTILLO ELIAS</option>
              <option value=" 935">SERGIO CORONA GONZALEZ</option>
              <option value=" 936">SIARA PATRICIA  LOPEZ RODRIGUEZ </option>
              <option value=" 937">SILVIA ELIZABETH BALLESTEROS DURAN</option>
              <option value=" 938">SILVIA MARGARITA GUZMAN RAMIREZ</option>
              <option value=" 939">SOCORRO  GOMEZ RODRIGUEZ </option>
              <option value=" 940">SUSANA VIDRIO HERNANDEZ</option>
              <option value=" 941">TELMA PIEDAD CEBALLOS AGUILAR</option>
              <option value=" 942">THALIA MARLENE CHAVEZ TOVAR </option>
              <option value=" 943">URIEL ALEJANDRO GUZMAN GARCIA </option>
              <option value=" 944">VARGAS LUIS DONALDO </option>
              <option value=" 945">VICENTE BARRAGAN ALVAREZ</option>
              <option value=" 946"> VICTOR ALFONSO MORAN CHAVEZ </option>
              <option value=" 947">VICTOR DE JESUS MARTINEZ RIVERA</option>
              <option value=" 948">VICTOR MANUEL  MENDOZA VALADEZ  </option>
              <option value=" 949"> VICTOR MANUEL  ORTEGA FIERRO</option>
              <option value=" 950">VIRIDIANA MARISOL CALVARIO ROBLES </option>
              <option value=" 951"> WENDY JANETH CAPACETE GARCIA</option>
              <option value=" 952"> XOCHITL ESTHER PATRÓN BOGARIN</option>
              <option value=" 953">YESENIA HURTADO TABARES</option>
              <option value=" 954">YULIANA GUADALUPE RODRIGUEZ MEDIAN </option>
              <option value=" 955">YURI FABIOLA  RADILLO GARCIA </option>
              <option value=" 956">ZAIRA GUILLERMINA MEDINA MARAVILLA</option>
              <option value=" 957"> ZOILA MAGDALENA RAYAS RODRIGUEZ </option>
              <option value=" 958"> ZORAYDA ARALY OREA GARCIA</option>
              <option value=" 959"> ZULEIMA JUDITH HERNANDEZ GODOY</option>








            </select>
















            <br><label style="margin-top: 2%; margin-right: 1%; font-weight: bold; font-weight: bold;">Seleccione el
              año:</label>
            <select formControlName="ANO" style="border-radius: 1vh;">
              <option value="2018">2018</option>
              <option value="2019">2019</option>
              <option value="2020">2020</option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
            </select>

            <br><label style="margin-top: 2%; margin-right: 1%; font-weight: bold; font-weight: bold;">Seleccione el
              documento a subir</label>
            <br><input type="file" style="margin-top: 1%;" (change)="contarArchivos($event)" formControlName="DOCUMENTO"
              multiple>

            <i class="bi bi-check-circle-fill" style="font-size: 30px; color: #00B59C;"
              *ngIf="!subiendoDocumento && urlDocumento !== '' "></i>

            <div class="spinner-border text-primary" role="status" *ngIf="subiendoDocumento"></div>

          </form>
        </div>
      </div>
    </div>

  </section>


  <!-- <qa href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></option> -->

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>
