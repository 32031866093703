<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>SAION - Tecnología gubernamental</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>



<footer id="footer">

  <div class="footer-top">
    <div class="container">
      <div class="row">

        <div class="col-lg-3 col-md-6 footer-links">
          <img src="assets/img/logo-footer.PNG" alt="">
        </div>



        <div class="col-lg-3 col-md-6 footer-contact">
          <h3>CONTACTO</h3>
          <p>
            Calle V. Carranza # 1 <br> Col. Centro, C.P. 48900 <br>
            Autlán,Jalisco<br>
            México <br><br>
            <strong>Télefono:</strong> +52 317 382 5100<br>
            <strong>Correo:</strong> contacto@autlan.gob.mx<br>

          </p>
        </div>

        <div class="col-lg-2 col-md-6 footer-links">
          <h4>Mapa del Sitio</h4>
          <ul>
            <li><i class="bx bx-chevron-right"></i> <a href="https://www.autlan.gob.mx/admin/indice">Inicio</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="https://www.autlan.gob.mx/admin/directorio">Directorio</a>
            </li>
            <li><i class="bx bx-chevron-right"></i> <a
                href="https://transparencia.autlan.gob.mx/admin/articulo-8">Transparencia</a></li>
            <li><i class="bx bx-chevron-right"></i> <a
                href="https://www.autlan.gob.mx/admin/tramites-y-servicios">Tramites y Servicios</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="admin/avisos-de-privacidad">Aviso de Privacidad</a></li>


          </ul>
        </div>

        <div class="col-lg-3 col-md-6 footer-links">



          <h5 style="color: aliceblue;">Transparencia</h5>
          <a href="https://www.plataformadetransparencia.org.mx"> <img height="100" width="150"
              style="border-radius: 2vh;" src="https://i.imgur.com/JEWiAvJ.png" alt=""> </a>

          <h5 style="color: aliceblue; margin-top: 2vh;">Obligaciones de Transparencia</h5>
          <a
            href="https://consultapublicamx.plataformadetransparencia.org.mx/vut-web/faces/view/consultaPublica.xhtml#inicio">
            <img height="100" width="150" style="border-radius: 2vh; margin-left: .5vh; "
              src="https://i.imgur.com/ffIEN5V.png" alt=""> </a>




        </div>


      </div>
    </div>
  </div>

  <div class="container d-md-flex py-4">

    <div class="me-md-auto text-center text-md-start">
      <div class="copyright">
        &copy; A U T L Á N

        <strong><span> GOBIERNO MUNICIPAL 2021 - 2024</span></strong> © Copyright autlan.gob.mx
      </div>

    </div>

  </div>
</footer>
